import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import { deepOrange, green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import { frFR } from '@mui/material/locale';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';



import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauVentilation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      type:this.props.type
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
    this.setState({ type: props.type})
  }

  componentDidMount() {
  }

  
  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function rendement(data){
      var color = "";

        if(data < 0){
          data = "0";
        }else if(data >= 100){
          color = "#2D7E43";
        }else if(data >= 90 && data < 100){
          color = "#97BA38";
        }else if(data < 90){
          color = "#e8b610";
        }

        return  (
          <center>
          <Avatar style={{backgroundColor:color, width:"60px", height:"25px", marginTop:"0.2em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </center>
        )
      }

      function rapportPlace(rapport){
     
        
          return (
            <center>
            <Avatar
              style={{  backgroundColor: "#0089e9", width: "45px", height: "25px", marginTop:"0.2em"}}
              variant="square"
            > 
                <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "white" }}>{rapport}</span>
            </Avatar>
            </center>
        
          )
        
      } 

    function reussite(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <center>
          <Avatar style={{backgroundColor:color, width:"50px", height:"25px", marginTop:"0.2em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </center>
        )
      
    }

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        
          <Grid container spacing={2}>
          <Grid item xs={8}>
            
          </Grid>
          <Grid item xs={4}>

              <Pagination
              style={{float:"right"}}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />

          </Grid>
          </Grid>
      );
    }

    const columns = [
      { 
        field: 'ligne',
        headerName:  <span class="lato-regular">#</span>,
        description: "#",
        width: 450,
        renderCell: (params) => (
          <span class="lato-regular" style={{fontSize:"12px"}}>{params.formattedValue}</span>
        )
      },
      { 
        field: 'nombreChevaux',
        headerName:  <span class="lato-regular">Nombre course</span>,
        description: "#",
        headerAlign:"center",
        align:"center",
        width: 150,
        renderCell: (params) => (
          <span class="lato-regular" style={{fontSize:"14px"}}>{params.formattedValue}</span>
        )
      },
      { 
        field: 'reussiteChevauxGagnant',
        headerName:  <span class="lato-regular">Gagnant</span>,
        description: "#",
        headerAlign:"center",
        align:"center",
        width: 150,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },
      { 
        field: 'reussiteChevauxPlace',
        headerName:  <span class="lato-regular">Dans les places</span>,
        description: "#",
        headerAlign:"center",
        align:"center",
        width: 150,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },

      { 
        field: 'rendementGagnant',
        headerName:  <span class="lato-regular">Rde SG</span>,
        description: "#",
        headerAlign:"center",
        align:"center",
        width: 150,
        renderCell: (params) => (
          rendement(params.formattedValue)
        )
      },

      { 
        field: 'rendementPlace',
        headerName:  <span class="lato-regular">Rde SP</span>,
        description: "#",
        headerAlign:"center",
        align:"center",
        width: 150,
        renderCell: (params) => (
          rendement(params.formattedValue)
        )
      },
     
  ]


    return(
      
      <DataGrid
      localeText={frFR.localeText}
      density="compact"
      hideFooter={false}
      getRowId={() => crypto.randomUUID()}
      disableRowSelectionOnClick
      rows={this.state.data}
      columns={columns}
      
      autoHeight

      initialState={{
        pagination: { paginationModel: { pageSize: 20 } },
      }}
      rowsPerPageOptions={[]}
     />

    )
  }
}