import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CardHeader from '@mui/material/CardHeader';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TableauPronostics from "./TableauPronostics";
import FiltrePronostics from "./FiltrePronostics";
import DialogResultat from "./DialogResultat";
import Tooltip from '@mui/material/Tooltip';
import DialogOnboardPronostics from "./DialogOnboardPronostics";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AlertTitle from '@mui/material/AlertTitle';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import ModuleSyntheseMax from './ModuleSyntheseMax';
import MenuIcon from '@mui/icons-material/Menu';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardContent } from "@mui/material";
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TableRow from '@mui/material/TableRow';
import DialogOnboard from "./DialogOnboard";
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';


export default class TableauBord extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loader: false,
          connect: false,
          openUser: false,
          taillePanelFiltre: "22vw",
          taillePanel: "78vw",
          openMenu:false,
          tab: "1",
          anchor:null
        }
    }
    
    componentWillReceiveProps(props) {        
    }

    componentDidMount() {
      this.auth("");
      this.message();
    }

    openUser = () => {
      this.setState({openUser: true});
    }

    fermerOnboard = () => {
      
    }

    deco = () => {
          localStorage.removeItem("utilisateur_dtf");
          localStorage.removeItem("pass_dtf");
          window.location.href = Const.URL;
        }

    closeUser = () => {
      this.setState({openUser: false});
    }

    handleCloseMenu = () => {
      this.setState({openMenu: false});
    }

    handleTab = (event: React.SyntheticEvent, newValue: string) => {
      this.setState({tab: newValue});
    }

    redirectPronostics = () => {
      window.location.href = Const.URL + "pronostics";
    }

    redirectEditeur = () => {
      window.location.href = Const.URL + "editeur";
    }


    handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({anchor: event.currentTarget});
      this.setState({openMenu: true});      
    }

    redirectHome = () => {
      window.location.href = Const.URL + "synthese";
    }

    redirectEditeur = () => {
      window.location.href = Const.URL + "editeur";
    }

    redirectBibliotheque = () => {
          window.location.href = Const.URL + "algoProno";
    }

    activeAide =() => {
          localStorage.setItem("onboard", 0);
          window.location.href = Const.URL;
        }

        message = () => {
    
          fetch(
            Const.URL_WS_MESSAGE,
            { retry: 0, retryDelay: 1000 }
          )
            .then((res) => res.json())
            .then((data) => {
             
              if(data != null){
                this.setState({message: data});
              }
              
              
            })
            .catch((error) => {
              console.log("Request failed", error);
            });
        }

     auth = (ipClient) => {
          
          let user =  localStorage.getItem("utilisateur_dtf");
          let pass = localStorage.getItem("pass_dtf");
    
          fetch(
            Const.URL_WS_AUTH + `?email=${user}&password=${pass}&ipClient=${ipClient}`,
            { retry: 0, retryDelay: 1000 }
          )
            .then((res) => res.json())
            .then((data) => {
             
             
              if(!data.register){
                this.setState({connect: false});
                
              }else{
                if(!data.expiration){
                  this.setState({connect: true});
                  this.setState({utilisateurConnect: data});
                  
                }
              }
             
              
              
            })
            .catch((error) => {
              console.log("Request failed", error);
              localStorage.removeItem("utilisateur_dtf");
              localStorage.removeItem("pass_dtf");
              this.setState({loader: false});
            });
        }

  
   
    render() {
        return (
            <div>

              {(localStorage.getItem("onboard") == null || localStorage.getItem("onboard") == 0) && (
                <DialogOnboard titre={"Trotstats ePro"} open={true} cookie={"onboard"} fermer={this.fermerOnboard}/>
              )}

               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomColor:"grey", borderBottomStyle:"inset" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.3em"}}></span> 
                  </div>

                  <Divider style={{marginLeft:"1em"}} orientation="vertical" flexItem />

<div style={{marginTop:"0.7em"}}>

<Tooltip title={<span class="lato-regular">Menu</span>}>
<IconButton
  aria-label="close"
  style={{marginLeft:"1em"}}
  onClick={this.handleOpenMenu}
  aria-haspopup="true"
>
    <MenuIcon style={{color:"black"}} />
</IconButton>
</Tooltip>

<Menu
  id="basic-menu"
  open={this.state.openMenu}
  anchorEl={this.state.anchor}
  onClose={this.handleCloseMenu}
  MenuListProps={{
    'aria-labelledby': 'basic-button',
  }}
>

<MenuItem onClick={this.handleCloseMenu} style={{cursor:"default", backgroundColor:"#E0E0E0"}}>
    <ListItemIcon>
      <SpaceDashboardOutlinedIcon style={{color:"black"}} fontSize="small" />
    </ListItemIcon>
    <ListItemText><span class="lato-bold">Tableau de bord</span></ListItemText>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
      
    </Typography>
  </MenuItem>
  
  
  <MenuItem onClick={this.redirectHome}>
    <ListItemIcon>
      <ArticleOutlinedIcon style={{color:"black"}} fontSize="small" />
    </ListItemIcon>
    <ListItemText><span class="lato-regular">Synthèses</span></ListItemText>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
      ⌘F1
    </Typography>
  </MenuItem>
  
  <Divider/>

<MenuItem onClick={this.redirectPronostics}>
                      <ListItemIcon>
                        <TuneOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">Gestion des Pronostics</span></ListItemText>
                      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                        ⌘F2
                      </Typography>
                    </MenuItem>

  <MenuItem onClick={this.redirectEditeur}>
    <ListItemIcon>
      <ModeEditOutlinedIcon style={{color:"black"}} fontSize="small" />
    </ListItemIcon>
    <ListItemText><span class="lato-regular">Éditeur de Pronostics</span></ListItemText>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
      ⌘F3
    </Typography>
  </MenuItem>

  <Divider/>
  
  <MenuItem onClick={this.redirectBibliotheque}>
                      <ListItemIcon>
                        <SmartToyOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">AlgoProno Labs</span></ListItemText>
                      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                        
                      </Typography>
                    </MenuItem>

</Menu>

 <Tooltip title={<span class="lato-regular">Aide</span>}>
                  <IconButton
                    style={{marginLeft:"1em"}}
                    aria-label="close"
                    onClick={this.activeAide}
                  >
                  <HelpOutlineOutlinedIcon style={{color:"black"}} />
                  </IconButton>
                  </Tooltip>


</div>

                </div>
                </Grid>
                <Grid item xs={6}>
                <div style={{float:"right", margin:"0.5em"}}>


{this.state.utilisateurConnect != null && this.state.utilisateurConnect.register && !this.state.utilisateurConnect.expiration && (
  <IconButton
  aria-label="close"
  style={{marginLeft:"1em"}}
  onClick={this.openUser}
>
   
    <FaceOutlinedIcon style={{color:"black"}} />
   
</IconButton>
)}           

{!this.state.connect && (
  <LockOutlinedIcon style={{color:"black", margin:"0.3em"}} />
)}           

</div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            
            <Container maxWidth="xl">
            <Box sx={{ height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white', overflow:'auto', overflowX:'hidden' }}>
            <div style={{marginTop:"1em"}}>
              <Card>
              <CardHeader
                title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Tableau de bord</span></div>}
                subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Quelques informations ...</span>}
              />

              <CardContent>
              {(this.state.message != null && (
                  <div>
                    
                    <Alert style={{marginBottom:"1em"}} severity={this.state.message.type}>
                      <span style={{fontSize:"14px"}} class="lato-regular">
                       <a style={{ textDecoration: "none", color: "inherit"}} href={this.state.message.lien} target="_blank">{this.state.message.message} </a>
                       </span>
                    </Alert>
                    
                  </div>
                ))}

                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Courses enregistrées</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Discipline du trot (attelé - monté)</span>}
                          />
                          <CardContent>
                            <center>
                              <span class="lato-bold" style={{fontSize:"20px"}}>+ 23 700</span>
                            </center>
                          </CardContent>        
                      </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Chevaux au départ</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Chevaux, drivers, entraineurs ...</span>}
                          />
                          <CardContent>
                            <center>
                              <span class="lato-bold" style={{fontSize:"20px"}}>+ 300 000</span>
                            </center>
                          </CardContent>        
                      </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Tests de stratégies réalisés</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Avec l’éditeur de pronostics Trotstats</span>}
                          />
                          <CardContent>
                            <center>
                              <span class="lato-bold" style={{fontSize:"20px"}}>+ 3 700</span>
                            </center>
                          </CardContent>        
                      </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Pronostics enregistrés</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Par les utilisateurs via l’éditeur Trotstats</span>}
                          />
                          <CardContent>
                            <center>
                              <span class="lato-bold" style={{fontSize:"20px"}}>263</span>
                            </center>
                          </CardContent>        
                      </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Rendement moyen</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Basé sur les pronostics utilisateurs</span>}
                          />
                          <CardContent>
                            <center>
                              <span class="lato-bold" style={{fontSize:"20px", color:"green"}}>124 %</span>
                            </center>
                          </CardContent>        
                      </Card>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Réussite moyenne</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Basé sur les pronostics utilisateurs</span>}
                          />
                          <CardContent>
                            <center>
                              <span class="lato-bold" style={{fontSize:"20px", color:"green"}}>57 %</span>
                            </center>
                          </CardContent>        
                      </Card>
                  </Grid>
                </Grid>

               <span class="lato-regular-italic" style={{fontSize:"12px"}}>* données mises à jour au 12 mars.</span>   
              </CardContent>
             
             
              <div style={{marginTop:"1em"}}>
              <Card>
              <CardHeader
                title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Accès rapide</span></div>}
                subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Accedez rapidement aux fonctionnalités</span>}
              />

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Synthèses</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Courses du jour et archive</span>}
                          />
                          <CardContent>
                            <Button fullWidth onClick={() => this.redirectHome()} style={{textTransform:"none", marginLeft:"2em", marginTop:"-1.4em", float:"right", marginBottom:"1em"}} variant="outlined" startIcon={<ArticleOutlinedIcon />}>
                                                <span class="lato-regular">Synthèse</span>
                                              </Button>
                          </CardContent>        
                      </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Gestion des Pronostics</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Mon dossier Personnel</span>}
                          />
                          <CardContent>
                          <Button fullWidth onClick={() => this.redirectPronostics()} style={{textTransform:"none", marginLeft:"2em", marginTop:"-1.4em", float:"right", marginBottom:"1em"}} variant="outlined" startIcon={<TuneOutlinedIcon />}>
                                                <span class="lato-regular">Pronostics</span>
                                              </Button>
                          </CardContent>        
                      </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                      <Card>
                          <CardHeader
                            title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Éditeur de Pronostics</span></div>}
                            subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Recherche et analyse</span>}
                          />
                          <CardContent>
                          <Button fullWidth onClick={() => this.redirectEditeur()} style={{textTransform:"none", marginLeft:"2em", marginTop:"-1.4em", float:"right", marginBottom:"1em"}} variant="outlined" startIcon={<ModeEditOutlinedIcon />}>
                              <span class="lato-regular">Éditeur</span>
                          </Button>
                          </CardContent>        
                      </Card>
              </Grid>
              </Grid>
              </CardContent>

              <div style={{marginTop:"2em"}}>
              <TabContext value={this.state.tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={this.handleTab} aria-label="lab API tabs example">
                <Tab style={{textTransform:"none"}} label={<span class="lato-regular">Notes de version</span>} value="1" />
                  <Tab style={{textTransform:"none"}} label={<span class="lato-regular">Foire aux questions</span>} value="2" />
                  
              
                </TabList>
              </Box>
              <TabPanel value="2">
              

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span"><span class="lato-bold">Où suivre votre actualité ?
                  </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <span class="lato-regular">
                  Nous sommes très actif sur le réseau social Twitter : <a href="https://x.com/Dataturf" target="_blank">lien vers notre page</a> et sur notre chaine Youtube : <a target="_blank" href="https://www.youtube.com/@dataturf3780">lien vers notre chaine</a>
                  </span>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{marginTop:"1em"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span"><span class="lato-bold">Est-ce que je dois installer un logiciel ?
                  </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <span class="lato-regular">
                  Non ! Trotstats est 100% en ligne. Vous accédez à votre espace directement depuis un navigateur internet, sans téléchargement ni installation.
                  </span>
                </AccordionDetails>
              </Accordion>



              <Accordion style={{marginTop:"1em"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span"><span class="lato-bold">Les données sont-elles mises à jour ?
                  </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <span class="lato-regular">
                  Oui, notre base de données est mise à jour quotidiennement et automatiquement, avec les derniers partants, résultats, performances, etc.
                  </span>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{marginTop:"1em"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span"><span class="lato-bold">Puis-je voir les résultats passés de mes pronostics ?
                  </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <span class="lato-regular">
                  Oui ! Une fois votre pronostics créé, vous pouvez le tester sur l’historique des courses et consulter son taux de réussite et son rendement (ROI) et bien d’autres indicateurs clés.
                  </span>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{marginTop:"1em"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span"><span class="lato-bold"> Qui utilise Trotstats aujourd’hui ?
                  </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <span class="lato-regular">
                  Des parieurs passionnés de trot, des turfistes expérimentés, mais aussi des débutants curieux de créer leurs propres stratégies ! Notre communauté grandit chaque jour.
                  </span>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{marginTop:"1em"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span"><span class="lato-bold"> Je ne suis pas un expert, Trotstats est-il fait pour moi ?
                  </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <span class="lato-regular">
                  Oui ! Notre interface est intuitive, et nous proposons des tutos, des guides, et une FAQ pour vous accompagner pas à pas.
                  </span>
                </AccordionDetails>
              </Accordion>

              <Accordion style={{marginTop:"1em"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography component="span"><span class="lato-bold">Puis-je tester Trotstats avant de m’abonner ?
                  </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <span class="lato-regular">
                  Oui ! Nous proposons un accès découverte pour que vous puissiez tester l’éditeur de pronostics et explorer la base de données.
                  <br/><br/>Retrouvez nos tarifs sur notre site internet : <a href="https://dataturf.fr" target="_blank">lien</a>
                  </span>
                </AccordionDetails>
              </Accordion>

              

              </TabPanel>
              <TabPanel value="1">


              <Card>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none",  fontSize:"14px", textTransform:"none"}}>Prochaine mise à jour</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">A venir en avril
</span>}
                    />
                    <CardContent>
                      
                    <div>
    <span class="lato-bold">Module Synthèse Max</span>
    <span class="lato-regular"> : refonte majeure avec un nouveau design et des analyses enrichies.</span>
</div>
<div style={{marginTop:"1em"}}>
    <span class="lato-bold">Module AlgoProno Labs</span>
    <span class="lato-regular"> : optimisation du moteur de recherche AlgoProno pour + de découverte.</span>
</div>
                      
                    </CardContent>        
                  </Card>

                  <Card style={{marginTop:"1em"}}>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none",  fontSize:"14px", textTransform:"none"}}>Dernière Actualité (28/03/25)</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Nouvelle fonctionnalité
</span>}
                    />
                    <CardContent>
                      
                      <div>
                      <span class="lato-bold">Prise de note</span>
                      <span class="lato-regular">: Ajout de la possibilité d'ajouter des notes sur les pronostics.</span>
                      </div>
                      
                    </CardContent>        
                  </Card>

              <Card style={{marginTop:"1em"}}>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none",  fontSize:"14px", textTransform:"none"}}>Actualité (24/03/25)</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Correction
</span>}
                    />
                    <CardContent>
                      
                      <div>
                      <span class="lato-bold">Module AlgoProno Labs</span>
                      <span class="lato-regular">: Correction du bug bloquant l'export des historiques de pronostics.</span>
                      </div>
                      
                    </CardContent>        
                  </Card>


                  <Card style={{marginTop:"1em"}}>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none",  background: "linear-gradient(to top, yellow 90%, transparent 30%)", fontSize:"14px", textTransform:"none"}}>Dernière Actualité (22/03/25)</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">De nouvelles fonctionnalités sont disponibles avec la mise à jour de mars !
</span>}
                    />
                    <CardContent>
                      
                      <div>
                      <span class="lato-bold">Indicateurs partant</span>
                      <span class="lato-regular">: Ajout d'indicateurs sur le nombre d'engagements des entraîneurs par jour et par course. Nouveau suivi pour les drivers et jockeys, indiquant le nombre de fois qu'ils sont engagés au cours de la journée.</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Filtres trotteur</span>
                      <span class="lato-regular">: Introduction d'une catégorie de critères binaires (oui/non) spécialisée sur la dernière course des trotteurs.

</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Critères Course</span>
                      <span class="lato-regular">: Une nouvelle catégorie d'indicateurs dédiée aux courses, permettant de filtrer ou d'exclure des courses en fonction de critères spécifiques sur les partants.</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Module AlgoProno Labs</span>
                      <span class="lato-regular">: Nouveau module optionnel disponible ! Explorez une bibliothèque de pronostics générés par notre algorithme.</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Redesign du menu</span>
                      <span class="lato-regular">: Une refonte complète du menu du logiciel pour une navigation plus fluide et intuitive.</span>
                      </div>

                    </CardContent>        
                  </Card>

                  <Card style={{marginTop:"1em"}}>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none", background: "linear-gradient(to top, yellow 90%, transparent 30%)", fontSize:"14px", textTransform:"none"}}>Actualité (24/02/25)</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">La mise à jour de février est disponible !</span>}
                    />
                    <CardContent>
                      
                      <div>
                      <span class="lato-bold">Nouveau pack de filtres pré-enregistrés</span>
                      <span class="lato-regular">: 88 nouveaux critères sur les trotteurs (Classements récents, Répartition des classements, Régularité, Séquences de classement, ... et bien plus encore !)</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Module SynthèseMax</span>
                      <span class="lato-regular">: Export des bilans et historiques vers un tableur compatible avec Microsoft Excel, OpenOffice, et autres formats similaires.</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Éditeur de pronostics</span>
                      <span class="lato-regular">: Activez la Simulation Monte-Carlo (Simulation du jeu d'un seul cheval sur des pronostics contenant plusieurs chevaux).</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Amélioration des exports</span>
                      <span class="lato-regular">: Ajout des rapports de jeu simple gagnant et placé (PMU) dans l'export de l'historique des pronostics.</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Optimisation</span>
                      <span class="lato-regular">: Meilleure vitesse des algorithmes de calcul des statistiques et indicateurs.</span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Interface</span>
                      <span class="lato-regular">: Ajustements et améliorations pour une expérience utilisateur plus fluide.</span>
                      </div>

                    </CardContent>        
                  </Card>

                  <Card style={{marginTop:"1em"}}>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Actualité du 10/02/25</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Nouveau module</span>}
                    />
                    <CardContent>
                      
                      <div>
                     
                      <span class="lato-regular">
                      Le module Synthèse Max est désormais accessible à tous les passionnés de synthèses ! Pour aller plus loin dans vos analyses, profitez d'un outil conçu pour affiner vos choix et maximiser vos performances.


                      </span>
                      </div>

                    </CardContent>        
                  </Card>

                  <Card style={{marginTop:"1em"}}>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none",  background: "linear-gradient(to top, yellow 90%, transparent 30%)",fontSize:"14px", textTransform:"none"}}>Actualité du 15/01/25</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">La mise à jour majeure est désormais disponible !</span>}
                    />
                    <CardContent>
                      
                      <div>
                     
                      <span class="lato-regular">
                      Elle apporte de nombreuses nouveautés et améliorations conçues avec la précieuse collaboration de nos utilisateurs, que je remercie chaleureusement :


                      </span>
                      </div>
                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Simulations étendues</span>
                      <span class="lato-regular">: Les données des années 2023, 2024 et 2025 sont désormais disponibles. De plus, un historique depuis 2016 (10 ans !) pourra être ajouté progressivement en fonction de vos retours et besoins.</span>
                      </div>

                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Navigation simplifiée</span>
                      <span class="lato-regular">: Un calendrier interactif vous permet d’accéder rapidement aux courses passées (2023, 2024, 2025).</span>
                      </div>

                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Affichage des arrivées</span>
                      <span class="lato-regular">: Les résultats des courses passées sont affichés directement au-dessus de la synthèse pour une meilleure visibilité.</span>
                      </div>

                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Organisation simplifiée</span>
                      <span class="lato-regular">: Seuls deux dossiers sont désormais maintenus : Pronostics Trotstats et Pronostics Personnels.</span>
                      </div>

                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Nouveaux filtres</span>
                      <span class="lato-regular">: Des filtres hippiques simples et intuitifs vous aident à bien débuter dans l’éditeur de pronostics.</span>
                      </div>

                      <div style={{marginTop:"1em"}}>
                      <span class="lato-bold">Performances accrues</span>
                      <span class="lato-regular">: Les simulations bénéficient désormais d’une vitesse d’exécution améliorée pour une expérience plus fluide.</span>
                      </div>

                    </CardContent>        
                  </Card>

                  <Card style={{marginTop:"1em"}}>
                    <CardHeader
                      title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Actualité</span></div>}
                      subheader={<span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">Toute notre actualité</span>}
                    />
                    <CardContent>
                     
                      <span class="lato-regular">
                      Consultez notre actualité sur notre page Twitter : <a href="https://x.com/Dataturf" target="_blank"> lien vers notre page </a>
                      </span>
  
                    </CardContent>        
                  </Card>


              </TabPanel>
            </TabContext>
            </div>

              </Card>
              </div>


             
              
             
             
             </Card>

             


            </div>
                
            </Box>
            </Container>

            </div>



            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                          open={this.state.openUser}
                          onClose={this.closeUser}
                          fullWidth
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">
                           {<span class="lato-bold">Mon Compte</span>}
                          </DialogTitle>
                          <IconButton
                            aria-label="close"
                            onClick={this.closeUser}
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                          <CloseIcon style={{color:"black"}} />
                          </IconButton>
                          <Divider/>
                          <DialogContent>
                          
                          {this.state.utilisateurConnect != null && (
                            <Table component={Paper}>  
                            <TableRow>
                            <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Utilisateur</span></TableCell>
                            <TableCell><span class="lato-regular" style={{fontSize:"15px"}}>{this.state.utilisateurConnect.utilisateur.email}</span></TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Abonnement</span></TableCell>
                            <TableCell><span class="lato-regular" style={{fontSize:"15px"}}>{this.state.utilisateurConnect.utilisateur.abonnment}</span></TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Expiration</span></TableCell>
                            <TableCell><span class="lato-regular" style={{fontSize:"15px"}}>{this.state.utilisateurConnect.utilisateur.expiration}</span></TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell align="left"><span class="lato-bold" style={{fontSize:"15px"}}>Session</span></TableCell>
                            <TableCell>
                            <Button 
                              fullWidth 
                              variant="outlined"
                              onClick={() => this.deco()}
                              startIcon={<ArrowRightIcon />}>
                              <span class="lato-regular" style={{ textTransform:"none", fontSize:"15px"}}>Se déconnecter</span>
                            </Button>
            
                            </TableCell>
                            </TableRow>
                            </Table>  
            
            
                            
                          )}
                          
                          </DialogContent>
                          <Divider/>
                          <DialogActions>
                          <Grid container spacing={2}>
                                  
                            <Grid item xs={6}>
                               
                            </Grid>
                            <Grid item xs={6}>
                              <IconButton
                                  aria-label="close"
                                  onClick={this.closeUser}
                                  style={{marginLeft:"1em", float:"right"}}
                                  //onClick={}
                                >
                                <CheckOutlinedIcon style={{color:"black"}} />
                                </IconButton>
                            </Grid>
                          </Grid>
                          
                          </DialogActions>
                        </Dialog>

            </div>

        )
    }
}