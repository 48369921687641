import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CardHeader from '@mui/material/CardHeader';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TableauPronostics from "./TableauPronostics";
import FiltrePronostics from "./FiltrePronostics";
import DialogResultat from "./DialogResultat";
import Tooltip from '@mui/material/Tooltip';
import DialogOnboardPronostics from "./DialogOnboardPronostics";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AlertTitle from '@mui/material/AlertTitle';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import ModuleSyntheseMax from './ModuleSyntheseMax';
import MenuIcon from '@mui/icons-material/Menu';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import TableauBibliotheque from './TableauBibliotheque';
import FiltreAlgoProno from "./FiltreAlgoProno";
import DialogOnboardAlgoProno from "./DialogOnboardAlgoProno";
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';



export default class Bibliotheque extends Component {

    constructor(props) {
        super(props);
        this.state = {
          bibliotheques: null,
          dossier: "Bases",
          loader: false,
          openDialog: false,
          result:null,
          zoom: false,
          taillePanelFiltre: "22vw",
          taillePanel: "78vw",
          typeProno: "info",
          idSelected:null,
          point:1,
          openMenu:false,
          anchor:null,
          openDialogEnregistrer: false
        }
        this.methodeNom = "Pronostics_" + Date.now();
        this.description = " ";
        this.condition = -1;
        this.periode = -1;
        this.jour = 0;
        this.recherche = "";
    }

    componentWillReceiveProps(props) {        
    }

    componentDidMount() {
      this.auth();
    }

    resize = () => {
      if(this.state.zoom){
        this.setState({zoom: false});
        this.setState({taillePanelFiltre: '22vw'});
        this.setState({taillePanel: '78vw'});
      }else{
        this.setState({taillePanelFiltre: '0vw'});
        this.setState({taillePanel: '100vw'});
        this.setState({zoom: true});
      } 
    }

    auth = () => {
      let user = localStorage.getItem("utilisateur_dtf");
      let pass = localStorage.getItem("pass_dtf");
      fetch(
        Const.URL_WS_AUTH + `?email=${user}&password=${pass}&ipClient=`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
         
          if(!data.register){
            window.location.href = Const.URL + "synthese";
          }else{
            this.setState({algoprono: data.utilisateur.algoprono});
            console.log(data)
            this.callBibliotheque();
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    closeDialog = () => {
      this.setState({openDialog: false});
    }

    filtres = (condition, periode, jour, recherche) => {
      this.condition = condition;
      this.periode = periode;
      this.jour = jour;
      this.recherche = recherche;
    
       
     this.callBibliotheque();
     }

    callBibliotheque = () => {
      this.setState({loader: true});
      fetch(
        Const.URL_WS_BIBLIOTHEQUES + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&recherche=${this.recherche}&condition=${this.condition}&periode=${this.periode}&jour=${this.jour}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({bibliotheques:data.bibliotheques});
          this.setState({loader: false});
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    wsSelectionSave = () => {
          
          let utilisateur = localStorage.getItem("utilisateur_dtf");
          this.setState({loader: true});
          
          fetch(
            Const.URL_WS_BIBLIOTHEQUE_SAVE + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&label=${this.methodeNom}&description=${this.description}&categorie=${this.state.dossier}&point=${this.state.point}&icone=${this.state.typeProno}&idMethode=${this.state.idSelected}`,
            { retry: 0, retryDelay: 1000 }
          )
            .then((res) => res.json())
            .then((data) => {
              this.setState({loader: false});
            })
            .catch((error) => {
              console.log("Request failed", error);
              this.setState({loader: false});
            });



          this.closeDialogEnregistrer();
        }

    handleNameSelection = (event) => {
      this.methodeNom =  event.target.value
    }

    handleSelectPoint = (event) => {
      this.setState({ point: event.target.value});
    }

    handleSelectTypeProno = (event) => {
      this.setState({ typeProno: event.target.value});
    }


    handleDescription = (event) => {
      this.description =  event.target.value
    }

    handleSelect = (event) => {
      this.setState({ dossier: event.target.value});
    }

    closeDialogEnregistrer = () => {
      this.setState({openDialogEnregistrer: false});
    }

    openDialogEnregistrer = (id) => {
      this.setState({idSelected: id});
      this.setState({openDialogEnregistrer: true});
    }

    redirectPronostics = () => {
      window.location.href = Const.URL + "pronostics";
    }

    redirectEditeur = () => {
      window.location.href = Const.URL + "editeur";
    }

    redirectSynthese = () => {
      window.location.href = Const.URL + "synthese";
    }


    handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({anchor: event.currentTarget});
      this.setState({openMenu: true});      
    }

    handleCloseMenu = () => {
      this.setState({openMenu: false});
    }

    redirectHome = () => {
      window.location.href = Const.URL + "synthese";
    }

    redirectEditeur = () => {
      window.location.href = Const.URL + "editeur";
    }

    redirectTableau = () => {
      window.location.href = Const.URL + "";
    }

    activeAide =() => {
      localStorage.setItem("onboardAlgoProno", 0);
      window.location.href = Const.URL + "algoProno";
    }

    render() {
        return (
            <div>

              {(localStorage.getItem("onboardAlgoProno") == null || localStorage.getItem("onboardAlgoProno") == 0) && (
                <DialogOnboardAlgoProno titre={"onboardAlgoProno"} open={true} cookie={"onboardAlgoProno"}/>
              )}

               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomColor:"grey", borderBottomStyle:"inset" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.3em"}}>- AlgoProno Labs</span> 
                  </div>

                  <Divider style={{marginLeft:"1em"}} orientation="vertical" flexItem />

<div style={{marginTop:"0.7em"}}>

<Tooltip title={<span class="lato-regular">Menu</span>}>
<IconButton
  aria-label="close"
  style={{marginLeft:"1em"}}
  onClick={this.handleOpenMenu}
  aria-haspopup="true"
>
    <MenuIcon style={{color:"black"}} />
</IconButton>
</Tooltip>

<Menu
  id="basic-menu"
  open={this.state.openMenu}
  anchorEl={this.state.anchor}
  onClose={this.handleCloseMenu}
  MenuListProps={{
    'aria-labelledby': 'basic-button',
  }}
>
  
<MenuItem onClick={this.redirectTableau}>
                      <ListItemIcon>
                        <SpaceDashboardOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">Tableau de bord</span></ListItemText>
                      
                    </MenuItem>

                     
                      <MenuItem  onClick={this.redirectSynthese}>
                        <ListItemIcon>
                          <ArticleOutlinedIcon style={{color:"black"}} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText><span class="lato-regular">Synthèses</span></ListItemText>
                        <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                          ⌘F1
                        </Typography>
                      </MenuItem>

                      <Divider/>
                    <MenuItem onClick={this.redirectPronostics}>
                      <ListItemIcon>
                        <TuneOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">Gestion des Pronostics</span></ListItemText>
                      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                        ⌘F2
                      </Typography>
                    </MenuItem>
                    
                    
                    
                    <MenuItem onClick={this.redirectEditeur}>
                      <ListItemIcon>
                        <ModeEditOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">Éditeur de Pronostics</span></ListItemText>
                      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                        ⌘F3
                      </Typography>
                    </MenuItem>
                    
                    <MenuItem  style={{cursor:"default", backgroundColor:"#E0E0E0"}} onClick={this.handleCloseMenu}>
                      <ListItemIcon>
                        <SmartToyOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-bold">AlgoProno Labs</span></ListItemText>
                      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                        
                      </Typography>
                    </MenuItem>


</Menu>

<Tooltip title={<span class="lato-regular">Aide</span>}>
<IconButton
  style={{marginLeft:"1em"}}
  aria-label="close"
  onClick={this.activeAide}
>
<HelpOutlineOutlinedIcon style={{color:"black"}} />
</IconButton>
</Tooltip>

</div>

                </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{float:"right", margin:"0.5em"}}>
                 
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectHome}
                  >
                    <Tooltip title={<span class="lato-regular">Retour synthèse</span>}>
                  <CloseIcon style={{color:"black"}} />
                  </Tooltip>
                  </IconButton>
                </div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            <Box sx={{ width: this.state.taillePanelFiltre, maxWidth: this.state.taillePanelFiltre, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA' , overflow:'scroll', overflowX:'hidden' }}>
              <div style={{margin:"1em"}}>
                <FiltreAlgoProno filtres={this.filtres}/>
              </div>
              
            </Box>

            <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white', overflow:'hidden', overflowX:'hidden' }}>

            <div>
                <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA', overflow:'scroll', overflowX:'hidden' }}>
                
                <div style={{margin:"1em"}}>
        <div style={{marginTop:"1em", marginBottom:"2em"}}>
        <center>
          <span class="lato-bold" style={{fontVariant:"small-caps", fontSize:"17px"}}>AlgoProno labs</span>
          <div style={{float:"right"}}>
          <IconButton onClick={() => this.resize()} aria-label="delete">
            {this.state.zoom && (
               <FullscreenExitOutlinedIcon style={{color:"black"}}/>
            )}
            {!this.state.zoom && (
              <FullscreenOutlinedIcon style={{color:"black"}}/> 
            )}
          </IconButton>
          </div>
          <div style={{marginTop:"1em", width:"70%"}}>
            <Divider/>
          </div>
        </center>
        </div>

            {(this.state.algoprono == "N" && (
                              <div>
                                
                                <Alert style={{ marginBottom: "1em" }} severity="info">
  <span style={{ fontSize: "14px" }} className="lato-regular">
  <b>AlgoPronos</b> est un module avancé de <b>Trotstats</b> qui utilise un algorithme pour générer des pronostics précis et rentables.
  <br/> Il analyse et teste diverses combinaisons de critères, toutes les 24 heures, afin d'identifier les pronostics les plus prometteurs.

  
  <br/><br/>
  En plus de ses capacités d’analyse, <b>AlgoPronos</b> s’adapte en continu à vos critères de recherche et aux préférences des utilisateurs.<br/>Il apprend de vos interactions pour rechercher de nouveaux pronostics, garantissant ainsi une expérience toujours plus efficace et optimisée.
    <br /><br />
    🔓 Débloquez l'accès dès maintenant !  
    <br/><br/>
    👉 
    <a href="https://www.youtube.com/watch?v=UUMhDGWD-0Y&ab_channel=Dataturf" target="_blank" style={{ color: "blue", textDecoration: "underline", fontWeight: "bold" }}>  
      vidéo de présentation 
    </a>
    <br/><br/>
    👉
    <a href="https://www.paypal.com/ncp/payment/S7JN6CBMAGBRW" target="_blank" style={{ color: "blue", textDecoration: "underline", fontWeight: "bold" }}>  
      lien vers le paiement 
    </a>
    <br/><br/>
    <i>
    En achetant ce module, vous bénéficiez d'un accès à vie, sans frais récurrents. Aucune nécessité de paiement mensuel, c'est un achat unique.
    </i>
  </span>
</Alert>
                                
                              </div>
                            ))}


        {this.state.bibliotheques != null && this.state.bibliotheques.length > 0 && (

          <div style={{marginTop:"1em"}}>
          <Card>
          <CardHeader
          
          title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Tableau des pronostics</span> <span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">{this.state.bibliotheques.length} résultat(s)</span></div>}
          
         />
            <TableauBibliotheque abo={this.state.algoprono} data={this.state.bibliotheques} update={this.callBibliotheque} save={this.openDialogEnregistrer} />
          </Card>

       
          </div>
        )}

{this.state.bibliotheques != null && this.state.bibliotheques.length == 0 && (
   <div style={{margin:"1em"}}>
   <Alert severity="info">
   <AlertTitle><span class="lato-bold">Aucun pronostic trouvé</span></AlertTitle>
   <span class="lato-regular">
      AlgoProno apprend de vos recherches et critères pour générer des pronostics optimisés.
   </span>
</Alert>
   </div>
)}
         
        </div>

                  
                
                    
                </Box>
            </div>
                
            </Box>

            </div>



            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>



<Dialog
              open={this.state.openDialogEnregistrer}
              onClose={this.closeDialogEnregistrer}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold">Enregistrer</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeDialogEnregistrer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
              
              <FormControl fullWidth style={{ marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier"
                    defaultValue={"Bases"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Bases"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels</span></MenuItem>
                  <MenuItem value={"Test"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Test</span></MenuItem>
                  <MenuItem value={"Archive"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Archive</span></MenuItem>
                  </Select>
                </FormControl>
                
                <TextField
                  style={{marginTop:"0.5em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  label={<span class="lato-regular">Nom</span>}
                  defaultValue={this.methodeNom}
                  InputProps={{
                    className: "lato-regular",
                    
                  }}
                  onChange={this.handleNameSelection}
                />

                <TextField
                  style={{marginTop:"1em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  label={<span class="lato-regular">Description</span>}
                  defaultValue={this.description}
                  multiline
                  rows={4}
                  maxRows={4}
                  InputProps={{
                    className: "lato-regular",
                    
                  }}
                  onChange={this.handleDescription}
                />

                <FormControl fullWidth style={{marginTop:"1em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Icône</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.typeProno}
                    label="Icône"
                    defaultValue={"1"}
                    onChange={this.handleSelectTypeProno}
                  >
                  <MenuItem value={"info"}><img src="https://dataturf.fr/images/info.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"good"}><img src="https://dataturf.fr/images/good.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"down"}><img src="https://dataturf.fr/images/down.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"warning_jaune"}><img src="https://dataturf.fr/images/warning_jaune.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"warning_rouge"}><img src="https://dataturf.fr/images/warning_rouge.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"money"}><img src="https://dataturf.fr/images/money.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  </Select>
               
                </FormControl>


                <FormControl fullWidth style={{marginTop:"1em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Bonus / Malus (points)</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.point}
                    label="Bonus / Malus (points)"
                    defaultValue={"1"}
                    onChange={this.handleSelectPoint}
                  >
                  <MenuItem value={"-5"}><span class="lato-regular" style={{fontSize:"14px", color : "#C53419"}}>- 5 point  (Malus)</span></MenuItem>
                  <MenuItem value={"-4"}><span class="lato-regular" style={{fontSize:"14px", color : "#C53419"}}>- 4 points (Malus)</span></MenuItem>
                  <MenuItem value={"-3"}><span class="lato-regular" style={{fontSize:"14px", color : "#C53419"}}>- 3 points (Malus)</span></MenuItem>
                  <MenuItem value={"-2"}><span class="lato-regular" style={{fontSize:"14px", color : "#D57B1A"}}>- 2 points (Malus)</span></MenuItem>
                  <MenuItem value={"-1"}><span class="lato-regular" style={{fontSize:"14px", color : "#D57B1A"}}>- 1 point  (Malus)</span></MenuItem>
                  <MenuItem value={"1"}><span class="lato-regular" style={{fontSize:"14px", color:"#97BA38"}}>+ 1 point  (Bonus)</span></MenuItem>
                  <MenuItem value={"2"}><span class="lato-regular" style={{fontSize:"14px", color:"#97BA38"}}>+ 2 points (Bonus)</span></MenuItem>
                  <MenuItem value={"3"}><span class="lato-regular" style={{fontSize:"14px", color:"#2D7E43"}}>+ 3 points (Bonus)</span></MenuItem>
                  <MenuItem value={"4"}><span class="lato-regular" style={{fontSize:"14px", color:"#2D7E43"}}>+ 4 points (Bonus)</span></MenuItem>
                  <MenuItem value={"5"}><span class="lato-regular" style={{fontSize:"14px", color:"#2D7E43"}}>+ 5 points (Bonus)</span></MenuItem>
                  
                  </Select>
                  <span class="lato-regular-italic" style={{fontSize:"13px"}}>Utilisez le bonus / malus pour attribuer les points aux chevaux du pronostics.</span>
                </FormControl>
                
                <Alert
                  style={{marginTop:"1em"}}
                  severity="info"
                >
                <span class="lato-regular" style={{fontSize:"15px"}}>Une fois enregistré, votre pronostic sera mis à jour automatiquement chaque jour et intégré dans votre synthèse personnalisée.</span>
                </Alert>


              </DialogContent>
              <Divider/>
              <DialogActions>

              <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.wsSelectionSave}
                  >
                  <CheckOutlinedIcon style={{color:"black"}} />
                  </IconButton>

                
               
              </DialogActions>
            </Dialog>


            
            </div>

        )
    }
}