import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Switch from '@mui/material/Switch';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import Tooltip from '@mui/material/Tooltip';


import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauSyntheseMax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }



  /************************************************************************************
   * RENDU
   ************
   
   ***********************************************************************/

  render() {

   

    function format(data){
      data = Number(data);
      return data.toLocaleString('fr-FR');
    }
    
        
      const columns = [
     
     
      { 
        field: 'date',
        headerName:  <span class="lato-regular">Date</span>,
        description: "#",
        headerAlign:"left",
        align: "left",
        width: 110,
        renderCell: (params) => (
          <span class="lato-regular">{params.formattedValue}</span>
        )
      },   
      
      
      
  ]


    return(
      <DataGrid
      density="compact"
      hideFooter={true}
      
      disableRowSelectionOnClick
      getRowId={() => crypto.randomUUID()}
      rows={this.state.data}
      columns={columns}
      autoHeight
      
      getRowClassName={(params) =>
        this.state.selectedRow === params.row.id ? 'selected-row' : ''
      }
      
      />


    )
  }
}