import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Container from '@mui/material/Container';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import PronosticsResultat from "./PronosticsResultat";
import TableauBacktest from "./TableauBacktest";
import TableauBacktestJeux from "./TableauBacktestJeux";
import TableauHistorique from "./TableauHistorique";
import TableauEcart from "./TableauEcart";
import TableauCombinaisonJeux from "./TableauCombinaisonJeux";
import ChartEcarts from "./ChartEcarts";
import ChartChevauxRetenu from "./ChartChevauxRetenu";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import ZoneRechercheFiltre from "./ZoneRechercheFiltre";
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import Button from '@mui/material/Button';


export default class ListFiltres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtres:this.props.filtres,
      tab: this.props.tab,
      selectedCritere: this.props.selectedCritere,
      openDialog:false,
      expressionFiltre: "",
      logique: "=",
      openSnackBar: false,
      checkReverse: false,
      titreCritere:this.props.titreCritere,
      rechercheFiltre: this.props.rechercheFiltre,
      critere: this.props.critere,
      abo: this.props.abo
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ filtres: props.filtres})
    this.setState({ titreCritere: props.titreCritere})
    this.setState({ tab: props.tab})
    this.setState({ selectedCritere: props.selectedCritere})
    this.setState({ rechercheFiltre: props.rechercheFiltre})
    this.setState({ abo: props.abo})
    console.log(this.state.selectedCritere)
  }

  componentDidMount() {
  }

  closeSnackBar = () => {
    this.setState({openSnackBar: false});
  }

  handleReverse = () => {
    if(this.state.checkReverse == true){
      this.setState({checkReverse: false});
    }else{
      this.setState({checkReverse: true});
    }
  }

  handleZoneRechercheFiltre = (obj) => {
    this.props.handleZoneRechercheFiltre(obj);
  }

  handleCheckbox = (obj) => {
    this.props.handleCheckbox(obj)
  }

  closeDialog = () => {
    this.setState({ labelFiltre: ""})
    this.setState({ logique: "="})
    this.setState({ expressionFiltre: ""})
    this.setState({ checkReverse: false})
    this.setState({ openDialog: false})
  }

  openDialog = () => {
    this.setState({ filtres: null})
    this.setState({ tab: null})
    this.setState({ openDialog: true})
  }

  save = () => {
    this.saveFiltre();
  }

  handlelogique = (event) => {
    this.setState({ logique: event.target.value})
  }

  saveFiltre = () => {
    fetch(
      Const.URL_WS_FILTRE_SAVE + `?idCritere=${this.state.selectedCritere.id}
      &label=${this.state.labelFiltre}
      &logique=${this.state.logique}
      &expression=${this.state.expressionFiltre}
      &checkReverse=${this.state.checkReverse}`,
      { retry: 0, retryDelay: 1000 }
    )
    .then((res) => res.json())
    .then((data) => {

      if(data == true){
        this.setState({ openSnackBar: true})
      }
      this.setState({ labelFiltre: ""})
      this.setState({ logique: "="})
      this.setState({ expressionFiltre: ""})
      this.setState({ checkReverse: false})
    })
    .catch((error) => {
        console.log("Request failed", error);
        this.setState({ labelFiltre: ""})
        this.setState({ logique: "="})
        this.setState({ expressionFiltre: ""})
        this.setState({ checkReverse: false})
    });



    this.setState({ openDialog: false})
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div>
      <List>
                <ListSubheader>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Filtres</span>
                </ListSubheader>
                {this.state.filtres != null && (
                  <div style={{margin:"1em"}} >
                  
                  <ZoneRechercheFiltre rechercheFiltre={this.state.rechercheFiltre} handleZoneRechercheFiltre={this.handleZoneRechercheFiltre}/>
                 
                </div>

                )}
                <div style={{margin:"1em"}}>
                <center>
                  <span class="lato-bold">{this.state.titreCritere}</span>
                  <Divider width="50px" style={{marginTop:"0.5em"}}/>
                </center>
                </div>

                {(this.state.abo == "Premium Essai" && this.state.selectedCritere.isPro == "O" && (
                                                  <div>
                                                    
                                                    <Alert style={{margin:"1em"}} severity={"warning"}>
                                                    <span style={{ fontSize: "14px" }} class="lato-regular">
                                                      Essai Premium - Accès restreint aux critères.<br/><br/>
                                                      Profitez de tous les filtres en passant à l’offre Premium !
                                                    </span>
                                                    </Alert>
                                                    
                                                  </div>
                                                ))}

                {this.state.filtres != null && this.state.filtres.filtres.map((o, index) => (
                  <div style={{margin:"1em", display: o.visible != '' ? 'block' : 'none'}}>
                  <ListItem >                   
                        <ListItemText style={{cursor:"pointer"}} primary={
                        <div>
                        <Checkbox
                          disabled={this.state.abo == "Premium Essai" && this.state.selectedCritere.isPro == "O" ? true : false}
                          size="small"
                          checked={this.state.tab != null && this.state.tab[o.id] != null ? this.state.tab[o.id] : false}
                          onChange={() => this.handleCheckbox(o)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          label="hello"
                        />
                        
                        <span class="lato-regular" style={{cursor:"pointer", fontSize:"14px", color:this.state.abo == "Premium Essai" && this.state.selectedCritere.isPro == "O" ? "grey" : "black"}} onClick={this.state.abo == "Premium Essai" && this.state.selectedCritere.isPro == "O" ? null : () => this.handleCheckbox(o)}>{o.valeur}</span>
                        </div>} />
                  </ListItem>
                  <Divider variant="inset" />
                  </div>
                ))}

                {this.state.filtres != null && this.state.filtres.filtres.length == 0 && (this.state.selectedCritere.id != 32  && this.state.selectedCritere.id != 33 && this.state.selectedCritere.id != 34 && this.state.selectedCritere.id != 35)  && (
                  <div style={{margin:"1em"}}>
                  <Alert severity="info">
                  <AlertTitle><span class="lato-bold">Aucun filtre n’est encore disponible pour ce critère.</span></AlertTitle>
                  <span class="lato-regular">Vous avez toutefois la possibilité de créer des filtres personnalisés pour ce critère.<br></br>
                  </span>
                  </Alert>
                  </div>  
                )}

{this.state.filtres != null && this.state.filtres.filtres.length == 0 && (this.state.selectedCritere.id == 32  || this.state.selectedCritere.id == 33 || this.state.selectedCritere.id == 34 || this.state.selectedCritere.id == 35)  && (
                  <div style={{margin:"1em"}}>
                  <Alert severity="info">
                  <AlertTitle><span class="lato-bold">Pour le critère "Nom", veuillez effectuer une recherche avec au moins 4 lettres.</span></AlertTitle>
                  
                  </Alert>
                  </div>  
                )}
                
                {this.state.selectedCritere != null && (this.state.selectedCritere.type == "CUSTOM_TEXTE" || this.state.selectedCritere.type == "CUSTOM_NOMBRE")  && (
                
                  <div style={{margin:"1em"}}>
                  <Alert severity="info">
                  <AlertTitle><span class="lato-bold">Ajoutez des filtres à ce critère</span></AlertTitle>
                  <span class="lato-regular">Vous ne trouvez pas certaines valeurs ?<br></br>
                  Ajoutez une ou des valeurs pour ce filtre.
                  </span>
                  
                  <Button onClick={() => this.openDialog()} disabled={this.state.abo == "Premium Essai" && this.state.selectedCritere.isPro == "O" ? true : false} style={{textTransform:"none", marginLeft:"2em", marginTop:"-1.4em", float:"right"}} variant="outlined" startIcon={<BuildOutlinedIcon />}>
                    <span class="lato-regular">Nouveau</span>
                  </Button>
                  </Alert>
                  </div>
                  
                )}
        </List>
    
    
        <Dialog
              open={this.state.openDialog}
              onClose={this.closeDialog}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold" style={{fontSize:"18px"}}>Édition filtre</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>

                {this.state.selectedCritere != null && (
                  <div style={{marginBottom:"1em"}}>
                  <span class="lato-bold">{this.state.selectedCritere.label}</span><br></br>
                  <span class="lato-regular"><i>{this.state.selectedCritere.labelCategorie}</i></span>
                  </div>
                )}

                {this.state.selectedCritere && 
                  this.state.selectedCritere.labelCategorie && 
                  this.state.selectedCritere.labelCategorie.includes("Classement") && (
                    <div style={{marginLeft:"-0.5em"}}>
                    <Checkbox
                          size="small"
                          checked={this.state.checkReverse}
                          onChange={() => this.handleReverse()}
                          inputProps={{ 'aria-label': 'controlled' }}
                          label="hello"
                        />
                        <span class="lato-regular" style={{cursor:"pointer", fontSize:"14px", marginLeft:"-0.2em"}} onClick={() => this.handleReverse()}>
                        Le classement sera effectué dans l'ordre décroissant.
                        </span>
                    </div>

                )}
                
              <TextField
                  style={{marginTop:"0.5em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  label={<span class="lato-regular">Étiquette</span>}
                  InputProps={{
                    className: "lato-regular",
                  }}
                  value={this.labelFiltre}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({labelFiltre: event.target.value});
                  }}
                />

        <FormControl style={{marginTop:"1em"}} fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.logique}
          defaultValue={"="}
          onChange={this.handlelogique}
          size="small"
        >
          <MenuItem value="="><span class="lato-regular" style={{fontSize:"15px"}}>Égal à</span></MenuItem>
          <MenuItem value="sup"><span class="lato-regular" style={{fontSize:"15px"}}>Supérieur ou égal à</span></MenuItem>
          <MenuItem value="inf"><span class="lato-regular" style={{fontSize:"15px"}}>Inférieur ou égal à</span></MenuItem>
          <MenuItem value="s"><span class="lato-regular" style={{fontSize:"15px"}}>Supérieur à</span></MenuItem>
          <MenuItem value="i"><span class="lato-regular" style={{fontSize:"15px"}}>Inférieur à</span></MenuItem>
          <MenuItem value="compris"><span class="lato-regular" style={{fontSize:"15px"}}>Compris entre</span></MenuItem>
          <MenuItem value="!="><span class="lato-regular" style={{fontSize:"15px"}}>Différent de</span></MenuItem>
          <MenuItem value="commence"><span class="lato-regular" style={{fontSize:"15px"}}>Commence par</span></MenuItem>
          <MenuItem value="termine"><span class="lato-regular" style={{fontSize:"15px"}}>Termine par</span></MenuItem>
          
        </Select>
      </FormControl>


              <TextField
                  style={{marginTop:"1em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  label={<span class="lato-regular">Valeur</span>}
                  InputProps={{
                    className: "lato-regular",
                  }}
                  value={this.state.expressionFiltre}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({expressionFiltre: event.target.value});
                  }}
                />
              
              <Alert style={{marginTop:"1em"}} severity="warning"><span style={{fontSize:"14px"}} class="lato-regular">Avant de créer vos premiers filtres, veuillez regarder la vidéo mémo dédiée. <a href='https://youtu.be/kRqPI0bZzFQ' target="_blank"> lien vers la vidéo</a></span></Alert>

              </DialogContent>
              <Divider/>
              <DialogActions>

              <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.save}
                  >
                  <CheckOutlinedIcon style={{color:"black"}} />
                  </IconButton>

              </DialogActions>
            </Dialog>
    
            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              severity="success"
              onClose={this.closeSnackBar}
              message="Enregistrer"
            >
               <Alert
                  onClose={this.closeSnackBar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                <span class="lato-regular" style={{fontSize:"14px"}}>Filtre enregistré</span>
              </Alert>
            </Snackbar>

      </div>
    )
  }
}