import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import PronosticsResultat from "./PronosticsResultat";
import TableauBacktest from "./TableauBacktest";
import TableauBacktestJeux from "./TableauBacktestJeux";
import TableauHistorique from "./TableauHistorique";
import TableauEcart from "./TableauEcart";
import TableauCombinaisonJeux from "./TableauCombinaisonJeux";
import ChartEcarts from "./ChartEcarts";
import ChartChevauxRetenu from "./ChartChevauxRetenu";
import ChartLine from "./ChartLine";
import TableauVentilation from "./TableauVentilation";
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import TableauBacktestJeuxSuite from "./TableauBacktestJeuxSuite";
import TableauBacktestReussite from "./TableauBacktestReussite";
import TableauCotation from "./TableauCotation";
import TableauCotationZoom from "./TableauCotationZoom";
import TableauRentabilite from "./TableauRentabilite";
import TableauRentabilitePlace from "./TableauRentabilitePlace";
import TableauMonteCarlo from "./TableauMonteCarlo";
import TableauMonteCarloBest from "./TableauMonteCarloBest";

export default class PanelResultat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      zoom: this.props.zoom
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
    this.setState({ zoom: props.zoom})
  }

  componentDidMount() {
  }

resize = () => {
  this.props.resizeWindows();
}


  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div style={{margin:"1em"}}>
        <div style={{marginTop:"1em", marginBottom:"2em"}}>
        <center>
          <span class="lato-bold" style={{fontVariant:"small-caps", fontSize:"17px"}}>résultats de la simulation</span>
          <div style={{float:"right"}}>
          <IconButton onClick={() => this.resize()} aria-label="delete">
            {this.state.zoom && (
               <FullscreenExitOutlinedIcon style={{color:"black"}}/>
            )}
            {!this.state.zoom && (
              <FullscreenOutlinedIcon style={{color:"black"}}/> 
            )}
          </IconButton>
          </div>
          <div style={{marginTop:"1em", width:"70%"}}>
            <Divider/>
          </div>
        </center>
        </div>
        {this.state.data != null &&  (
          <div>
          <div style={{display:"flex"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauBacktest data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
              <TableauBacktestJeux data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauBacktestJeuxSuite data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
            <TableauBacktestReussite data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauEcart data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
              <TableauCombinaisonJeux data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauCotation data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
            <TableauCotationZoom data={this.state.data} />
            </div>
          </div>
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
              <TableauRentabilite data={this.state.data} />
            </div>
            <div style={{width:"50vw"}}>
              <TableauRentabilitePlace data={this.state.data} />
            </div>
          </div>

   
          {this.state.data != null && this.state.data.monteCarlo != null && (
           <div>          
           <div style={{marginTop:"2em", marginBottom:"2em"}}>
           <center>
             <span class="lato-bold" style={{fontVariant:"small-caps", fontSize:"17px"}}>simulation monte-carlo</span>
             <div style={{marginTop:"1em", width:"70%"}}>
               <Divider/>
             </div>
           </center>
           </div>
          
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
            <TableauMonteCarlo data={this.state.data.monteCarlo} />
            </div>
            <div style={{width:"50vw"}}>
            <TableauMonteCarloBest data={this.state.data.monteCarlo} />
            </div>
          </div>
          <center>
             <div style={{marginTop:"1em", width:"70%"}}>
               <Divider/>
             </div>
           </center>
          </div>
          )}

          
          <div style={{display:"flex", marginTop:"1em"}}>
            <div style={{width:"50vw", marginRight:"1em"}}>
            <ChartEcarts data={this.state.data.analyse.histoEcarts} />
            </div>
            <div style={{width:"50vw"}}>
            <ChartChevauxRetenu data={this.state.data.analyse.histoChevauxRetenu} />
            </div>
            
          </div>
          
          
          {this.state.data.analyse.jour != null && this.state.data.analyse.jour.length > 0 &&  (
          <div style={{marginTop:"1em"}}>
          <Card>
          <CardHeader
          
          title={<span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Aujourd'hui</span>}
          
         />
          {this.state.data.methode.warning &&  (
             <Alert style={{margin:"1em"}} severity="info">
              <span class="lato-regular" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>{this.state.data.methode.messageWarning}</span>
             </Alert>
          )}
            <TableauHistorique data={this.state.data.analyse.jour}/>
          </Card>
          </div>
          )}
         
          {this.state.data.analyse.jour == null ||this.state.data.analyse.jour.length == 0  &&  (
            <div style={{marginTop:"1em"}}>
              <Alert severity="info">
              <span class="lato-regular" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Aucun partant n'a été détecté pour les courses du jour.</span>
              </Alert>
            </div>
          )}

          
          
          <div style={{marginTop:"1em"}}>
          <Card>
          <CardHeader
          
          title={<span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Permanence des jeux</span>}
          
         />
            <TableauHistorique data={this.state.data.analyse.historiques}/>
          </Card>
          </div>
        </div>
        )}

        
      {this.state.data != null && this.state.data.ventilation != null && (
        <div>          
        <div style={{marginTop:"2em", marginBottom:"2em"}}>
        <center>
          <span class="lato-bold" style={{fontVariant:"small-caps", fontSize:"17px"}}>dans les jumelles ®</span>
          <div style={{marginTop:"1em", width:"70%"}}>
            <Divider/>
          </div>
        </center>
        </div>
      
      {Object.keys(this.state.data.ventilation).map(key => (
        <div key={key} style={{marginTop:"1em"}}>
          
          <Card>
          <CardHeader
          
          title={<span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>{key}</span>}
          
         />
          <div style={{margin:"1em"}}>
            <TableauVentilation type={key} data={this.state.data.ventilation[key]} />
          </div>   
          </Card>
          </div>
      ))}

        </div>

        )}
       

        {this.state.data != null &&  (
          <Card style={{marginTop:"1em"}}>
             <CardContent>
              <div style={{display:"flex"}}>
              <span class="lato-regular" style={{fontSize:"14px"}}>Analyse effectuée en {this.state.data.tempsExec} ms</span>
              </div>
              </CardContent>
              

          </Card>
        
        )}
      
      </div>
    )
  }
}