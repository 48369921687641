import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import ChoixPeriode from "./ChoixPeriode";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import { BlurCircular, QueuePlayNext, RotateRight } from "@mui/icons-material";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogOnboardEditeur from "./DialogOnboardEditeur";
import DialogAide from "./DialogAide";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default class Editeur extends Component {

    constructor(props) {
        super(props);
        this.state = {
         openDialogEnregistrer: false,
         loader: false,
         openSnackBar: false,
         dossier:"Pronostics Personnelles",
         selectionSelected: null,
         conditionSelected: null,
         periodeSelected: 0,
         resultatBacktest: null,
         restriction: 0,  
         exclusion: 0,
         tri: 0,
         checked: localStorage.getItem("simu") == 1 ? true : false,
         checkedJumelles: localStorage.getItem("jumelles") == 1 ? true : false,
         checkedMonteCarlo: localStorage.getItem("montecarlo") == 1 ? true : false,
         checkedZeturf: localStorage.getItem("zeturf") == 1 ? true : false,
         dossier: "Bases",
         aideOption:false,
         aideOptionMonteCarlo:false,
         itemVentilation: null,
         itemVentilationType: null,
         point:1,
         zoom: false,
         taillePanelFiltre: "30vw",
         taillePanel: "70vw",
         dateDebut: "",
         dateFin: "",
         abo:"",
         typeProno: "info",
         openMenu:false,
          anchor:null
        }
        this.methodeNom = "Pronostics_" + Date.now();
        this.description = " ";
        this.note = "";
        
        
        
    }

    aideOption = () => {
      this.setState({ aideOption: true})
    }

    aideOptionMonteCarlo = () => {
      this.setState({ aideOptionMonteCarlo: true})
    }
  
    aideOptionRapport = () => {
      this.setState({ aideOptionRapport: true})
    }

    closeAideOption = () => {
      this.setState({ aideOption: false})
    }

    closeAideOptionMonteCarlo = () => {
      this.setState({ aideOptionMonteCarlo: false})
    }

    closeAideOptionRapport = () => {
      this.setState({ aideOptionRapport: false})
    }
  
    resize = () => {
      if(this.state.zoom){
        this.setState({zoom: false})
        this.setState({taillePanelFiltre: '30vw'});
        this.setState({taillePanel: '70vw'});
      }else{
        this.setState({taillePanelFiltre: '0vw'});
        this.setState({taillePanel: '100vw'});
        this.setState({zoom: true});
      } 
    }

    componentWillReceiveProps(props) {
    }

    componentDidMount() {
      this.auth();
    }

    auth = () => {
      let user = localStorage.getItem("utilisateur_dtf");
      let pass = localStorage.getItem("pass_dtf");
      fetch(
        Const.URL_WS_AUTH + `?email=${user}&password=${pass}&ipClient=`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
          
          this.setState({ abo: data.utilisateur.abonnment});
          
          if(!data.register || data.expiration){
            window.location.href = Const.URL + "synthese";
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    handleRestriction = (event) => {
      this.setState({ restriction: event.target.value});
      if(this.state.checked){
        this.runTest();
      }
    }

    handleExclusion = (event) => {
      this.setState({ exclusion: event.target.value});
      if(this.state.checked){
        this.runTest();
      }
    }

    handleTri = (event) => {
      this.setState({ tri: event.target.value});
      if(this.state.checked){
        this.runTest();
      }
    }

    closeDialogEnregistrer = () => {
      this.setState({itemVentilation: null});
      this.setState({itemVentilationType: null});

      this.setState({openDialogEnregistrer: false});
    }

    handleCheck = () => {
      if(this.state.checked == false){
        this.setState({ checked: true }, () => {
          localStorage.setItem("simu", 1);
        });
      
      }else{
        this.setState({ checked: false }, () => {
          localStorage.setItem("simu", 0);
        });
      }
    }

    handleCheckJumelles = () => {
      if(this.state.checkedJumelles == false){
        this.setState({ checkedJumelles: true }, () => {
          localStorage.setItem("jumelles", 1);
        });
      
      }else{
        this.setState({ checkedJumelles: false }, () => {
          localStorage.setItem("jumelles", 0);
        });
      }
    }

    handleCheckMonteCarlo = () => {
      if(this.state.checkedMonteCarlo == false){
        this.setState({ checkedMonteCarlo: true }, () => {
          localStorage.setItem("montecarlo", 1);
        });
      
      }else{
        this.setState({ checkedMonteCarlo: false }, () => {
          localStorage.setItem("montecarlo", 0);
        });
      }
    }

    handleCheckZeturf = () => {
      if(this.state.checkedZeturf == false){
        this.setState({ checkedZeturf: true }, () => {
          localStorage.setItem("zeturf", 1);
        });
      
      }else{
        this.setState({ checkedZeturf: false }, () => {
          localStorage.setItem("zeturf", 0);
        });
      }
    }

    openDialogEnregistrer = () => {
      this.methodeNom = "Pronostics_" + Date.now();
      this.description = " ";
      this.note = "";
      this.setState({point: 1});
      this.setState({openDialogEnregistrer: true});
    }

    handleSelection = (selection) => {
      this.setState({selectionSelected: selection});
      this.setState({resultatBacktest: null});
     
      this.setState({ selectionSelected: selection }, () => {
        if(this.state.conditionSelected != null && this.state.conditionSelected.id != null && this.state.selectionSelected !=null && this.state.selectionSelected.id != null && this.state.checked){
          this.runTest();
        }
       });

    }

    handleCondition = (condition) => {
      this.setState({resultatBacktest: null});
     
      this.setState({ conditionSelected: condition }, () => {
        if(this.state.checked && this.state.conditionSelected != null && this.state.selectionSelected.id != null){
          this.runTest();
        }
       });
    }

    handlePeriodeOptionDebut = (dateDebut) => {
      try{
        this.setState({dateDebut: dateDebut.toISOString().split('T')[0]});
      }catch (error) {}
    }

    handlePeriodeOptionFin = (dateFin) => {
      try{
        this.setState({dateFin: dateFin.toISOString().split('T')[0]});
      }catch (error) {}
    }

    handlePeriode = (periode, dateDebut, dateFin) => {
      this.setState({resultatBacktest: null});
      
      this.setState({ periodeSelected: periode }, () => {
        if(this.state.checked && this.state.conditionSelected != null && this.state.selectionSelected.id != null ){
          this.runTest();
        }
       });
    }

    save = (item, type) => {
      console.log("editeur <> " + this.state.selectionSelected.id + " <> " + type + " <> " + item.ligne)
      this.setState({itemVentilation: item.ligne});
      this.setState({itemVentilationType: type});

      this.setState({openDialogEnregistrer: true});
    }

    wsSelectionSave = () => {
      this.description = encodeURIComponent(this.description)
      this.note = encodeURIComponent(this.note)
      let utilisateur = localStorage.getItem("utilisateur_dtf");
      this.setState({loader: true});
      fetch(
        Const.URL_WS_METHODE_SAVE + `?utilisateur=${utilisateur}
        &label=${this.methodeNom}
        &description=${this.description}
        &selection=${this.state.selectionSelected.id}
        &conditionCourse=${this.state.conditionSelected.id}
        &periode=${this.state.periodeSelected.id}
        &restriction=${this.state.restriction}
        &exclusion=${this.state.exclusion}
        &categorie=${this.state.dossier}
        &sousCategorie=
        &pays=FRANCE
        &itemVentilation=${this.state.itemVentilation}
        &itemVentilationType=${this.state.itemVentilationType}
        &point=${this.state.point}
        &icone=${this.state.typeProno}
        &tri=${this.state.tri}
        &rapportAlt=${this.state.checkedZeturf}
        &note=${this.note}`,
        { retry: 0, retryDelay: 1000 }
      )
      .then((res) => res.json())
      .then((data) => {
        if(data == true){
          this.setState({loader: false});
          this.setState({openSnackBar: true});
          
        }else{
          alert("Erreur, merci de nous contacter pour résoudre le problème.");
          this.setState({loader: false});
        }
      })
      .catch((error) => {
        this.setState({loader: false});
          console.log("Request failed", error);
      });
      this.closeDialogEnregistrer();
    }

    runTest = () =>  {
      
      this.setState({loader: true});
      fetch(
        Const.URL_WS_METHODE_TEST + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&selection=${this.state.selectionSelected.id}
        &conditionCourse=${this.state.conditionSelected.id}
        &periode=${this.state.periodeSelected.id}
        &restriction=${this.state.restriction}
        &exclusion=${this.state.exclusion}
        &ia=${this.state.checkedJumelles}
        &periodeDebut=${this.state.dateDebut}
        &periodeFin=${this.state.dateFin}
        &tri=${this.state.tri}
        &monteCarlo=${this.state.checkedMonteCarlo}
        &rapportAlt=${this.state.checkedZeturf}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({resultatBacktest: data});
          this.setState({loader: false});
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    closeSnackBar = () => {
      this.setState({openSnackBar: false});
    }

    handleSelect = (event) => {
      this.setState({ dossier: event.target.value});
    }

    handleSelectPoint = (event) => {
      this.setState({ point: event.target.value});
    }

    handleSelectTypeProno = (event) => {
      this.setState({ typeProno: event.target.value});
    }

    

    handleNameSelection = (event) => {
      this.methodeNom =  event.target.value
    }

    handleDescription = (event) => {
      this.description =  event.target.value
    }

    handleNote = (value) => {
      this.note = value
    }

    redirectHome = () => {
      window.location.href = Const.URL + "synthese";
    }

     redirectBibliotheque = () => {
          window.location.href = Const.URL + "algoProno";
        }

    activeAide =() => {
      localStorage.setItem("onboardEditeur", 0);
      window.location.href = Const.URL + "editeur";
    }

      redirectPronostics = () => {
          window.location.href = Const.URL + "pronostics";
        }
    
        redirectTableau = () => {
          window.location.href = Const.URL;
        }
       
    
        handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
          this.setState({anchor: event.currentTarget});
          this.setState({openMenu: true});      
        }
    
        handleCloseMenu = () => {
          this.setState({openMenu: false});
        }

    render() {

      const modules = {
        toolbar: [
          [{ 'color': [] }, { 'background': [] }], // Ajoute les boutons de couleur
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link'],
          ['clean']
        ]
      };

        return (
            <div>

              {(localStorage.getItem("onboardEditeur") == null || localStorage.getItem("onboardEditeur") == 0) && (
                <DialogOnboardEditeur titre={"onboardEditeur"} open={true} cookie={"onboardEditeur"}/>
              )}

         {this.state.aideOption && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAideOption}
    content={
      <span class="lato-regular" style={{fontSize:"15px"}}>Pour approfondir l'analyse d'une sélection, vous pouvez utiliser ces trois options :
      <ul>
        <li style={{marginTop:"1em"}}>
          En activant <b>Dans les jumelles</b>, Trotstats analysera les performances de chaque acteur (chevaux, drivers et entraîneurs) présents dans votre pronostics.<br></br>
        </li>
        <li style={{marginTop:"1em"}}>
          Grâce à la fonction <b>Ordonnancement</b>, Trotstats permet de trier les chevaux du pronostic par ordre croissant ou décroissant en fonction de leur numéro de partant.<br></br>
        </li>
        <li style={{marginTop:"1em"}}>
          En activant <b>Exclusion pronostics</b>, Trotstats exclura les courses en fonction du nombre de chevaux sélectionnés par course.<br></br>
        </li>
        <li style={{marginTop:"1em"}}>
          En activant <b>Restriction pronostics</b>, qui est utile lorsque la sélection donne des pronostics pour plusieurs chevaux, Trotstats ne conservera que le cheval choisi.
        </li>
      </ul>
      </span>

    }
    />
    )}


{this.state.aideOptionMonteCarlo && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAideOptionMonteCarlo}
    content={
      <span class="lato-regular" style={{fontSize:"15px"}}>En activant <b>Simulation de Monte-Carlo</b>, Trotstats imite le comportement d’un parieur souhaitant miser sur un seul cheval par course.
      <ul>
        <li style={{marginTop:"1em"}}>
          <span class="lato-regular">À chaque course, un cheval est sélectionné aléatoirement parmi ceux retenus par vos critères.</span>
        </li>
        <li style={{marginTop:"1em"}}>
          <span class="lato-regular">La simulation est répétée des centaines de fois pour évaluer le rendement réel de votre sélection.</span>
        </li>
        <li style={{marginTop:"1em"}}>
          <span class="lato-regular">Vous obtenez une vision réaliste des gains et pertes que pourrait rencontrer un joueur dans des conditions réelles.</span>
        </li>
      </ul>
      
      <span class="lato-regular">Cette option est particulièrement utile lorsque votre sélection contient plusieurs chevaux par course, permettant ainsi d’évaluer l'impact du choix d’un seul cheval sur la rentabilité globale.</span>
      </span>

    }
    />
    )}

{this.state.aideOptionRapport && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAideOptionRapport}
    content={
      <div>
      <span class="lato-regular" style={{fontSize:"15px"}}>En activant <b>Rapports alternatifs</b>, Trotstats utilisera les rapports des jeux simples gagnants et placés Zeturf pour tous les calculs financiers. Par défaut, le logiciel s’appuie sur les rapports des jeux simples en ligne fournis par l’opérateur PMU</span>
      
      <br/><br/>
      <span class="lato-regular" style={{color:"red"}}>Les rapports alternatifs ne sont pas encore pris en compte lors de l'export de la permanence.</span>
      </div>
    }
    />
    )}

               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomColor:"grey", borderBottomStyle:"inset" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.3em"}}>- Éditeur de pronostics</span> 
                  </div>
                  <Divider style={{marginLeft:"1em"}} orientation="vertical" flexItem />

<div style={{marginTop:"0.7em"}}>

<Tooltip title={<span class="lato-regular">Menu</span>}>
<IconButton
  aria-label="close"
  style={{marginLeft:"1em"}}
  onClick={this.handleOpenMenu}
  aria-haspopup="true"
>
    <MenuIcon style={{color:"black"}} />
</IconButton>
</Tooltip>

<Menu
  id="basic-menu"
  open={this.state.openMenu}
  anchorEl={this.state.anchor}
  onClose={this.handleCloseMenu}
  MenuListProps={{
    'aria-labelledby': 'basic-button',
  }}
>

<MenuItem onClick={this.redirectTableau}>
                      <ListItemIcon>
                        <SpaceDashboardOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">Tableau de bord</span></ListItemText>
                      
                    </MenuItem>
  
  <MenuItem onClick={this.redirectHome}>
    <ListItemIcon>
      <ArticleOutlinedIcon style={{color:"black"}} fontSize="small" />
    </ListItemIcon>
    <ListItemText><span class="lato-regular">Synthèses</span></ListItemText>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
      ⌘F1
    </Typography>
  </MenuItem>
  
  <Divider/>

  
  
  <MenuItem onClick={this.redirectPronostics}>
                      <ListItemIcon>
                        <TuneOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">Gestion des Pronostics</span></ListItemText>
                      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                        ⌘F2
                      </Typography>
                    </MenuItem>

                    <MenuItem onClick={this.handleCloseMenu} style={{cursor:"default", backgroundColor:"#E0E0E0"}}>
                        <ListItemIcon>
                          <ModeEditOutlinedIcon style={{color:"black"}} fontSize="small" />
                        </ListItemIcon>
                        <ListItemText><span class="lato-bold">Éditeur de Pronostics</span></ListItemText>
                        <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                          ⌘F3
                        </Typography>
                      </MenuItem>
                      <Divider/>
                      <MenuItem onClick={this.redirectBibliotheque}>
                      <ListItemIcon>
                        <SmartToyOutlinedIcon style={{color:"black"}} fontSize="small" />
                      </ListItemIcon>
                      <ListItemText><span class="lato-regular">AlgoProno Labs</span></ListItemText>
                      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft:"2em" }}>
                        
                      </Typography>
                    </MenuItem>
</Menu>

<Tooltip title={<span class="lato-regular">Aide</span>}>
<IconButton
  style={{marginLeft:"1em"}}
  aria-label="close"
  onClick={this.activeAide}
>
<HelpOutlineOutlinedIcon style={{color:"black"}} />
</IconButton>
</Tooltip>

</div>
<Divider style={{marginLeft:"1em"}} orientation="vertical" flexItem />

<div style={{marginTop:"0.7em"}}>

<IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={() => this.runTest()}
                  >
                  <Tooltip title={<span class="lato-regular">Lancer le test</span>}>
                    <PlayCircleOutlinedIcon style={{color:"black"}} />
                  </Tooltip>
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={() => this.openDialogEnregistrer()}
                  >
                  <Tooltip title={<span class="lato-regular">Enregistrer</span>}>
                    <CloudUploadIcon style={{color:"black"}} />
                  </Tooltip>
                  </IconButton>



</div>
                </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{float:"right", margin:"0.5em"}}>
                  

                  

                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectHome}
                  >
                  <Tooltip title={<span class="lato-regular">Retour pronostics</span>}>
                    <CloseIcon style={{color:"black"}} />
                  </Tooltip>
                  </IconButton>
                </div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            <Box sx={{ width: this.state.taillePanelFiltre, maxWidth: this.state.taillePanelFiltre, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA' , overflow:'scroll', overflowX:'hidden' }}>
              <div style={{margin:"1em"}}>
                <ChoixSelection handleSelection={this.handleSelection}/>
              </div>
              <div style={{margin:"1em"}}>
                <ChoixCondition handleCondition={this.handleCondition}/>
              </div>
              <div style={{margin:"1em"}}>
                <ChoixPeriode abo={this.state.abo} handlePeriode={this.handlePeriode} handlePeriodeOptionDebut={this.handlePeriodeOptionDebut} handlePeriodeOptionFin={this.handlePeriodeOptionFin}/>
              </div>

              <div style={{margin:"1em"}}>
                <Card>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div  style={{margin:"1em", float:"left"}}>
                  <Switch
                    checked={this.state.checked}
                    size="small"
                    disabled
                    onChange={() => this.handleCheck()}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /> <span class="lato-regular" style={{fontSize:"14px"}}>Simulation auto.</span>
                   </div>

                  <div style={{float:"right"}}>
                  <IconButton
                    aria-label="close"
                    style={{margin:"0.5em", float:"right"}}
                    onClick={() => this.runTest()}
                  >
                    <Tooltip title={<span class="lato-regular">Lancer le test</span>}>
                      <PlayCircleOutlinedIcon style={{color:"black"}} />
                    </Tooltip>
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    style={{margin:"0.5em", float:"right"}}
                    onClick={() => this.openDialogEnregistrer()}
                  >
                  <Tooltip title={<span class="lato-regular">Enregistrer</span>}>
                    <CloudUploadIcon style={{color:"black"}} />
                  </Tooltip>
                  </IconButton>
                  </div>
                  </Grid>
                  
                  <Grid item xs={12}>
                  <div style={{marginLeft:"1em", marginRight:"1em", marginBottom:"1.5em"}}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        
                      >
                        <div style={{float:"left"}}>
                        <span class="lato-bold" style={{fontSize:"14px"}}>Options sur l'analyse</span>
                        </div>
                        
                      </AccordionSummary>
                      <AccordionDetails>

                      <FormControl fullWidth style={{float:"left"}}>
                    <div  style={{float:"left"}}>
                      <Switch
                        checked={this.state.checkedZeturf}
                        size="small"
                        onChange={() => this.handleCheckZeturf()}
                        inputProps={{ 'aria-label': 'controlled' }}
                      /> <span class="lato-regular" style={{fontSize:"14px"}}>Rapports alternatifs</span>
                    <IconButton
                       
                       aria-label="close"
                     onClick={() => this.aideOptionRapport}
                   >
                   <Tooltip title={<span class="lato-regular">Aide</span>}>
                     <HelpOutlineOutlinedIcon style={{color:"black", cursor:"pointer"}} onClick={this.aideOptionRapport} />
                   </Tooltip>
                   </IconButton>
                    </div>   
                    </FormControl>

                      <FormControl fullWidth style={{float:"left", marginTop:"1em"}}>
                    <div  style={{float:"left"}}>
                      <Switch
                        checked={this.state.checkedMonteCarlo}
                        size="small"
                        onChange={() => this.handleCheckMonteCarlo()}
                        inputProps={{ 'aria-label': 'controlled' }}
                      /> <span class="lato-regular" style={{fontSize:"14px"}}>Simulation Monte-Carlo</span>
                    <IconButton
                       
                       aria-label="close"
                     onClick={() => this.aideOptionMonteCarlo}
                   >
                   <Tooltip title={<span class="lato-regular">Aide</span>}>
                     <HelpOutlineOutlinedIcon style={{color:"black", cursor:"pointer"}} onClick={this.aideOptionMonteCarlo} />
                   </Tooltip>
                   </IconButton>
                    </div>   
                    </FormControl>

                      <FormControl fullWidth style={{float:"left", marginTop:"1em"}}>
                    <div  style={{float:"left"}}>
                      <Switch
                        checked={this.state.checkedJumelles}
                        size="small"
                        onChange={() => this.handleCheckJumelles()}
                        inputProps={{ 'aria-label': 'controlled' }}
                      /> <span class="lato-regular" style={{fontSize:"14px"}}>Dans les jumelles ® </span>
                       <IconButton
                       
                        aria-label="close"
                      onClick={() => this.aideOption}
                    >
                    <Tooltip title={<span class="lato-regular">Aide</span>}>
                      <HelpOutlineOutlinedIcon style={{color:"black", cursor:"pointer"}} onClick={this.aideOption} />
                    </Tooltip>
                    </IconButton>
                    </div>   
                    </FormControl>

                    <FormControl fullWidth style={{marginTop:"2.0em"}}>
                      <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Ordonnancement</span></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        label="Ordonnancement"
                        value={this.state.tri}
                        onChange={this.handleTri}
                      >
                        <MenuItem value={0}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Par numéro de partant croissant</span>
                        </MenuItem>
                        <MenuItem value={1}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Par numéro de partant décroissant</span>
                        </MenuItem>
                        
                        
                      </Select>
                    </FormControl>


                    <FormControl fullWidth style={{marginTop:"1.0em"}}>
                      <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Exclusion pronostics</span></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        label="Exclusion pronostics"
                        value={this.state.exclusion}
                        onChange={this.handleExclusion}
                      >
                        <MenuItem value={0}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Aucune</span>
                        </MenuItem>
                        <MenuItem value={1}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Exclure les pronostics plus d'un cheval</span>
                        </MenuItem>
                        <MenuItem value={2}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Exclure les pronostics plus deux chevaux</span>
                        </MenuItem>
                        <MenuItem value={3}>  
                          <span class="lato-regular" style={{fontSize:"14px"}}>Exclure les pronostics plus trois chevaux</span>
                        </MenuItem>
                        <MenuItem value={4}>  
                          <span class="lato-regular" style={{fontSize:"14px"}}>Exclure les pronostics plus quatre chevaux</span>
                        </MenuItem>
                        
                      </Select>
                    </FormControl>
                    
                    
                    <FormControl fullWidth style={{marginTop:"1.5em"}}>
                      <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Restriction pronostics</span></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        label="Restriction pronostics"
                        value={this.state.restriction}
                        onChange={this.handleRestriction}
                      >
                        <MenuItem value={0}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Aucune, afficher tous les chevaux</span>
                        </MenuItem>
                        <MenuItem value={1}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 1er cheval</span>
                        </MenuItem>
                        <MenuItem value={2}>  
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 2ème cheval</span>
                        </MenuItem>
                        <MenuItem value={3}>  
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 3ème cheval</span>
                        </MenuItem>
                        <MenuItem value={4}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 4ème cheval</span>
                        </MenuItem>
                        <MenuItem value={5}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 5ème cheval</span>
                        </MenuItem>
                        <MenuItem value={6}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 6ème cheval</span>
                        </MenuItem>
                        <MenuItem value={7}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 7ème cheval</span>
                        </MenuItem>
                        <MenuItem value={8}>
                          <span class="lato-regular" style={{fontSize:"14px"}}>Seulement le 8ème cheval</span>
                        </MenuItem>
                        
                      </Select>
                    </FormControl>



                      </AccordionDetails>
                    </Accordion>
                    </div>

                  </Grid>

                  </Grid>

                </Card>

                
              </div>

            </Box>

            <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white', overflow:'hidden', overflowX:'hidden' }}>

            <div>
                <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA', overflow:'scroll', overflowX:'hidden' }}>

                {this.state.resultatBacktest != null && (
                 <PanelResultat data={this.state.resultatBacktest} save={this.save} resizeWindows={this.resize} zoom={this.state.zoom} />
                )}

                {this.state.resultatBacktest == null && (
                 <div class="loadingImage">
                  <center>
                 <img width={"50%"} height={"50%"} style={{opacity:0.1}} src="https://dataturf.fr/images/brain.jpg" alt="Brain Image"/>
                 </center>
                  </div>
                )}
  
                </Box>
            </div>
                
            </Box>

            </div>


            <Dialog
              open={this.state.openDialogEnregistrer}
              onClose={this.closeDialogEnregistrer}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold">Enregistrer</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeDialogEnregistrer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
              
              <FormControl fullWidth style={{ marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier"
                    defaultValue={"Bases"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Bases"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels</span></MenuItem>
                  <MenuItem value={"Test"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Test</span></MenuItem>
                  <MenuItem value={"Archive"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Archive</span></MenuItem>
                  </Select>
                </FormControl>
                
                <TextField
                  style={{marginTop:"0.5em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  label={<span class="lato-regular">Nom</span>}
                  defaultValue={this.methodeNom}
                  InputProps={{
                    className: "lato-regular",
                    
                  }}
                  onChange={this.handleNameSelection}
                />

               <TextField
                  style={{marginTop:"1em"}}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="outlined-controlled"
                  label={<span class="lato-regular">Description</span>}
                  defaultValue={this.description}
                  multiline
                  rows={4}
                  maxRows={4}
                  InputProps={{
                    className: "lato-regular",
                    
                  }}
                  onChange={this.handleDescription}
                />

                
                <ReactQuill modules={modules} style={{marginTop:"1em"}} placeholder="Créer une note..." theme="snow" value={this.note} onChange={this.handleNote} />
                

                <FormControl fullWidth style={{marginTop:"1em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Icône</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.typeProno}
                    label="Icône"
                    defaultValue={"1"}
                    onChange={this.handleSelectTypeProno}
                  >
                  <MenuItem value={"info"}><img src="https://dataturf.fr/images/info.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"good"}><img src="https://dataturf.fr/images/good.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"down"}><img src="https://dataturf.fr/images/down.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"warning_jaune"}><img src="https://dataturf.fr/images/warning_jaune.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"warning_rouge"}><img src="https://dataturf.fr/images/warning_rouge.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"money"}><img src="https://dataturf.fr/images/money.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"horse"}><img src="https://dataturf.fr/images/horse.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  <MenuItem value={"driver"}><img src="https://dataturf.fr/images/driver.png" width="25px" style={{marginRight:"0.5em"}}/></MenuItem>
                  </Select>
               
                </FormControl>


                <FormControl fullWidth style={{marginTop:"1em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Bonus / Malus (points)</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.point}
                    label="Bonus / Malus (points)"
                    defaultValue={"1"}
                    onChange={this.handleSelectPoint}
                  >
                  <MenuItem value={"-5"}><span class="lato-regular" style={{fontSize:"14px", color : "#C53419"}}>- 5 point  (Malus)</span></MenuItem>
                  <MenuItem value={"-4"}><span class="lato-regular" style={{fontSize:"14px", color : "#C53419"}}>- 4 points (Malus)</span></MenuItem>
                  <MenuItem value={"-3"}><span class="lato-regular" style={{fontSize:"14px", color : "#C53419"}}>- 3 points (Malus)</span></MenuItem>
                  <MenuItem value={"-2"}><span class="lato-regular" style={{fontSize:"14px", color : "#D57B1A"}}>- 2 points (Malus)</span></MenuItem>
                  <MenuItem value={"-1"}><span class="lato-regular" style={{fontSize:"14px", color : "#D57B1A"}}>- 1 point  (Malus)</span></MenuItem>
                  <MenuItem value={"1"}><span class="lato-regular" style={{fontSize:"14px", color:"#97BA38"}}>+ 1 point  (Bonus)</span></MenuItem>
                  <MenuItem value={"2"}><span class="lato-regular" style={{fontSize:"14px", color:"#97BA38"}}>+ 2 points (Bonus)</span></MenuItem>
                  <MenuItem value={"3"}><span class="lato-regular" style={{fontSize:"14px", color:"#2D7E43"}}>+ 3 points (Bonus)</span></MenuItem>
                  <MenuItem value={"4"}><span class="lato-regular" style={{fontSize:"14px", color:"#2D7E43"}}>+ 4 points (Bonus)</span></MenuItem>
                  <MenuItem value={"5"}><span class="lato-regular" style={{fontSize:"14px", color:"#2D7E43"}}>+ 5 points (Bonus)</span></MenuItem>
                  
                  </Select>
                  <span class="lato-regular-italic" style={{fontSize:"13px"}}>Utilisez le bonus / malus pour attribuer les points aux chevaux du pronostics.</span>
                </FormControl>
                
                <Alert
                  style={{marginTop:"1em"}}
                  severity="info"
                >
                <span class="lato-regular" style={{fontSize:"15px"}}>Une fois enregistré, votre pronostic sera mis à jour automatiquement chaque jour et intégré dans votre synthèse personnalisée.</span>
                </Alert>


              </DialogContent>
              <Divider/>
              <DialogActions>

              <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.wsSelectionSave}
                  >
                  <CheckOutlinedIcon style={{color:"black"}} />
                  </IconButton>

                
               
              </DialogActions>
            </Dialog>

            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              severity="success"
              onClose={this.closeSnackBar}
              message="Enregistrer"
            >
               <Alert
                  onClose={this.closeSnackBar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                <span class="lato-regular" style={{fontSize:"15px"}}>Pronostics enregistré</span>
              </Alert>
            </Snackbar>


            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            </div>

        )
    }
}