import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";
import Checkbox from '@mui/material/Checkbox';


export default class FiltrePronostics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utilisateur:this.props.utilisateur,
      dossier:"Pronostics Trotstats",
      label:"",
      dynamique: "-",
      selection: "-",
      reussiteGagnant: "-",
      reussitePlace: "-",
      pronoJour: false,
      aideFiltre: false
    };
    
  }


  aideFiltre = () => {
    this.setState({ aideFiltre: true})
  }

  closeAideFiltre = () => {
    this.setState({ aideFiltre: false})
  }

  componentWillReceiveProps(props) {
    this.setState({ utilisateur: props.utilisateur})
  }

  componentDidMount() {
    
  }

  keyPress = (e) => {
    if(e.keyCode == 13){
       this.props.filtres(this.state.dossier, this.state.recherche, this.state.selection,
        this.state.dynamique, this.state.reussiteGagnant, this.state.reussitePlace,this.state.pronoJour)
    }
  }

  handleSelect = (event) => {
    this.setState({ dossier: event.target.value});
    this.props.filtres(event.target.value, this.state.recherche, this.state.selection,
      this.state.dynamique, this.state.reussiteGagnant, this.state.reussitePlace, this.state.pronoJour)
  }

  handleDynamique = (event) => {
    this.setState({ dynamique: event.target.value});
    this.props.filtres(this.state.dossier, this.state.recherche, this.state.selection,
      event.target.value, this.state.reussiteGagnant, this.state.reussitePlace, this.state.pronoJour)
  }

  handleSelection = (event) => {
    this.setState({ selection: event.target.value});
    this.props.filtres(this.state.dossier, this.state.recherche, event.target.value,
      this.state.dynamique, this.state.reussiteGagnant, this.state.reussitePlace, this.state.pronoJour)
  }

  handleReussiteGagnant = (event) => {
    this.setState({ reussiteGagnant: event.target.value});
    this.props.filtres(this.state.dossier, this.state.recherche, this.state.selection,
      this.state.dynamique, event.target.value, this.state.reussitePlace, this.state.pronoJour)
  }

  handleReussitePlace = (event) => {
    this.setState({ reussitePlace: event.target.value});
    this.props.filtres(this.state.dossier, this.state.recherche, this.state.selection,
      this.state.dynamique, this.state.reussiteGagnant, event.target.value, this.state.pronoJour)
  }

  handleCheckbox = () => {
    if(this.state.pronoJour){
      this.setState({pronoJour: false})
      this.props.filtres(this.state.dossier, this.state.recherche, this.state.selection,
        this.state.dynamique, this.state.reussiteGagnant, this.state.reussitePlace, false)
    }else{
      this.setState({pronoJour: true})
      this.props.filtres(this.state.dossier, this.state.recherche, this.state.selection,
        this.state.dynamique, this.state.reussiteGagnant, this.state.reussitePlace, true)
    }

   
  }
 
  


  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div>

    {this.state.aideFiltre && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideFiltre}
    content={<span class="lato-regular" style={{fontSize:"15px"}}>
      <b>
      Comment utiliser le bloc Filtres ?
      </b>
      <br/><br/>
      <b>
      1 - Explorez les pronostics :
      </b>
      <br/><br/>
      Grâce au bloc Filtres, parcourez facilement tous les pronostics enregistrés. Les différentes options disponibles vous permettent d’identifier rapidement ceux qui répondent à vos critères.
      <br/><br/>
      <b>
      2 - Personnalisez votre synthèse :
      </b>
      <br/><br/>
      Cet écran vous offre également la possibilité de créer une synthèse personnelle en combinant plusieurs pronostics, pour une analyse sur mesure adaptée à vos besoins.
      <br/><br/>
      <b>
      3 - Tableau des pronostics :
      </b>
      <br/><br/>
      Le tableau des pronostics vous propose une vue synthétique des pronostics générés par le logiciel ainsi que de vos propres analyses.
      <br/><br/>
      Fichier ressource concernant <b>l'indicateur Emax</b> : <a href="https://dataturf.fr/doc/Dataturf_Emax.pdf" target="_blank">lien vers le fichier</a>
      
      </span>}
    />
    )}

      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              F
          </Avatar>
          }
          action={
          <div>
         
              <HelpOutlineOutlinedIcon onClick={this.aideFiltre}  style={{color:"black", margin:"0.3em", cursor:"pointer"}}/>
         
         
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Filtres</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Recherche de pronostics.</span>}
      />
      <CardContent>
     
      <FormControl fullWidth style={{ marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier"
                    defaultValue={"Pronostics Trotstats"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Pronostics Trotstats"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics Trotstats</span></MenuItem>
                  <MenuItem value={"Bases"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels</span></MenuItem>
                  <MenuItem value={"Test"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Test</span></MenuItem>
                  <MenuItem value={"Archive"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Archive</span></MenuItem>
                  <MenuItem value={"Corbeille"}><span class="lato-regular" style={{fontSize:"14px"}}>Corbeille</span></MenuItem>
                  
                  </Select>
        </FormControl>

        <TextField
                  style={{ marginBottom:"1em"}}
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchOutlinedIcon/>,
                    className: "lato-regular",
                  }}
                  id="outlined-controlled"
                  onKeyDown={this.keyPress}
                  autoComplete={"off"}
                  size="small"
                  label={<span style={{color:"black"}} class="lato-regular">Recherche</span>}
                  value={this.state.recherche}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({recherche: event.target.value});
                  }}
                />


              <Grid container spacing={2}>
                      <Grid item xs={12}>
                          <Checkbox
                                size="small"
                                checked={this.state.pronoJour}
                                onChange={() => this.handleCheckbox()}
                                inputProps={{ 'aria-label': 'controlled' }}
                                label="hello"
                              />
                              <span class="lato-regular" style={{cursor:"pointer", fontSize:"14px", marginLeft:"-0.2em"}} onClick={() => this.handleCheckbox()}>Seulement pronostics du jour</span>
      
                      </Grid>
                </Grid>

       
          <div style={{marginTop:"0.5em"}}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                
              >
                <span class="lato-bold" style={{fontSize:"14px"}}>Filtres avancés</span>
              </AccordionSummary>
              <AccordionDetails>
              <FormControl fullWidth style={{ marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Sélection (chevaux / course)</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.selection}
                    label="Sélection (chevaux / course)"
                    defaultValue={"0"}
                    onChange={this.handleSelection}
                  >
                  <MenuItem value={"-"}><span class="lato-regular" style={{fontSize:"14px"}}>-</span></MenuItem>
                  <MenuItem value={"1"}><span class="lato-regular" style={{fontSize:"14px"}}>Un seul cheval</span></MenuItem>
                  <MenuItem value={"2"}><span class="lato-regular" style={{fontSize:"14px"}}>Peu de chevaux</span></MenuItem>
                  <MenuItem value={"3"}><span class="lato-regular" style={{fontSize:"14px"}}>Un grand nombre de chevaux</span></MenuItem>
                  </Select>
              </FormControl>
      

                <div>
                <FormControl fullWidth style={{ marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dynamique de jeu</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dynamique}
                    label="Dynamique de jeu"
                    defaultValue={"0"}
                    onChange={this.handleDynamique}
                  >
                  <MenuItem value={"-"}><span class="lato-regular" style={{fontSize:"14px"}}>-</span></MenuItem>
                  <MenuItem value={"1"}><span class="lato-regular" style={{fontSize:"14px"}}>Faible</span></MenuItem>
                  <MenuItem value={"2"}><span class="lato-regular" style={{fontSize:"14px"}}>Moyenne</span></MenuItem>
                  <MenuItem value={"3"}><span class="lato-regular" style={{fontSize:"14px"}}>Haute</span></MenuItem>
                  </Select>
              </FormControl>
                </div>

                <div>
                <FormControl fullWidth style={{ marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Réussite (cheval gagnant)</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.reussiteGagnant}
                    label="Réussite (cheval gagnant)"
                    defaultValue={"-"}
                    onChange={this.handleReussiteGagnant}
                  >
                  <MenuItem value={"-"}><span class="lato-regular" style={{fontSize:"14px"}}>-</span></MenuItem>
                  <MenuItem value={"1"}><span class="lato-regular" style={{fontSize:"14px"}}>Faible</span></MenuItem>
                  <MenuItem value={"2"}><span class="lato-regular" style={{fontSize:"14px"}}>Moyenne</span></MenuItem>
                  <MenuItem value={"3"}><span class="lato-regular" style={{fontSize:"14px"}}>Haute</span></MenuItem>
                  </Select>
              </FormControl>
                </div>

                <div>
                <FormControl fullWidth style={{ marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Réussite (chevaux placés)</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.reussitePlace}
                    label="Réussite (chevaux placés)"
                    defaultValue={"-"}
                    onChange={this.handleReussitePlace}
                  >
                  <MenuItem value={"-"}><span class="lato-regular" style={{fontSize:"14px"}}>-</span></MenuItem>
                  <MenuItem value={"1"}><span class="lato-regular" style={{fontSize:"14px"}}>Faible</span></MenuItem>
                  <MenuItem value={"2"}><span class="lato-regular" style={{fontSize:"14px"}}>Moyenne</span></MenuItem>
                  <MenuItem value={"3"}><span class="lato-regular" style={{fontSize:"14px"}}>Haute</span></MenuItem>
                  </Select>
              </FormControl>
                </div>

              
              
              </AccordionDetails>
            </Accordion>
            </div>

    
      </CardContent>
      </Card>

     
      </div>


    )
  }
}