import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';

import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import ZoneRechercheFiltre from "./ZoneRechercheFiltre";
import CircularProgress from '@mui/material/CircularProgress';
import ListFiltres from "./ListFiltres";
import Tooltip from '@mui/material/Tooltip';
import { AltRouteRounded, ConstructionOutlined } from "@mui/icons-material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default class EditeurSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
          tab: [],
          selections: [],
          descriptions: [],
          openDialogEnregistrer: false,
          recherche: "",
          rechercheFiltre: "",
          selectedIndex: 0,
          titreCritere:"Le trotteur a déjà fini placé sur cet hippodrome (3 dern. mois)",
          selectionFiltres: "",
          openDrawerAide: false,
          sousCategorie: "INDICATEUR_PRE_ENREGISTRE",
          selectionNom: "Sélection_" + Date.now(),
          dossier: "Sélections Personnelles",
          openSnackBar: false,
          loader: false,
        }

        this.tab = [];
    }

    componentWillReceiveProps(props) {
        
    }

    componentDidMount() {
        this.auth();

        let o = {};
        o.id = 551;  // TODO a remettre suivant l'id 
        o.aide = "Le trotteur a déjà fini placé sur cet hippodrome (3 dern. mois)";
        this.setState({selectedCritere: o});
        this.wsCriteres();
        this.wsFiltres(o);

        let idSelectionModifie = localStorage.getItem("idSelectionModifie");

        if(idSelectionModifie != null){
          this.loadSelectionModifie(idSelectionModifie);
          localStorage.removeItem("idSelectionModifie")
        }
    }

     auth = () => {
          let user = localStorage.getItem("utilisateur_dtf");
          let pass = localStorage.getItem("pass_dtf");
          fetch(
            Const.URL_WS_AUTH + `?email=${user}&password=${pass}&ipClient=`,
            { retry: 0, retryDelay: 1000 }
          )
            .then((res) => res.json())
            .then((data) => {
              
              this.setState({ abo: data.utilisateur.abonnment});
              
              if(!data.register || data.expiration){
                window.location.href = Const.URL + "synthese";
              }
            })
            .catch((error) => {
              console.log("Request failed", error);
              this.setState({loader: false});
            });
        }

    closeDialogEnregistrer = () => {
      this.setState({openDialogEnregistrer: false});
    }

    closeSnackBar = () => {
      this.setState({openSnackBar: false});
    }

    closeDrawerAide = () => {
      this.setState({openDrawerAide: false});
    }

    openDialogEnregistrer = () => {
      this.setState({selectionNom: "Sélection_" + Date.now()});
      this.setState({openDialogEnregistrer: true});
    }

    handleSelect = (event) => {
      this.setState({ dossier: event.target.value});
    }

    handleZoneRechercheFiltre = (valeur) => {
      this.setState({ rechercheFiltre: valeur }, () => {
        this.wsFiltres(this.state.selectedCritere);
      });
    }
    
    

    handleSousCategorie = (event) => {
      this.setState({ recherche: ""});
      this.setState({ rechercheFiltre: ""});
      this.setState({ sousCategorie: event.target.value }, () => {
        this.wsCriteres();
      });
      
    }

    loadFiltre = (id) => {
      
      fetch(
        Const.URL_WS_FILTRE + `?&id=${id}`,
        { retry: 0, retryDelay: 1000 }
       )
        .then((res) => res.json())
        .then((data) => {
           console.log(data);
           this.handleCheckbox(data)
           
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }

    loadSelectionModifie = (idSelection) => {
      fetch(
        Const.URL_WS_SELECTION + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&idSelection=${idSelection}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
           console.log(data);
           let tab = data.selection.idFiltres.split(";")
           for (let element of tab) {
            if(element > 0){
              this.loadFiltre(element)
            }
           }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }

    redirectHome = () => {
      window.location.href = Const.URL + "editeur";
    }

    handleCheckbox = (o) => {
      console.log(o)
      let copytab = this.state.tab;
      let copySelections = this.state.selections;
      let copyDescriptions = [];

      if(copytab[o.id] == true){
        copytab[o.id] = false;  
        copySelections = copySelections.filter(item => item.id !== o.id);
      }else{
        copytab[o.id] = true;
        copySelections.push(o);
      }

      let filtres = "";  
      let aide = "";
      for (const item of copySelections) {
        filtres +=  ';' + item.id;

        let description= {};
        description  = { ...item };
  
        // Rechercher un objet avec le même idCritere
        let existingSelection = copyDescriptions.find(des => des.idCritere === description.idCritere);
        
        if (existingSelection) {
            // Si un objet avec le même idCritere existe, concaténer la valeur
            existingSelection.valeur += `,  ${description.valeur}`;
        } else {
            // Sinon, ajouter le nouvel objet à la liste
            copyDescriptions.push(description);
        }
      }

      for (const item of copyDescriptions) {
        aide += item.categorie + "," + item.label + "," + item.valeur + ";";
      }

      this.setState({ descriptions: copyDescriptions})
      this.setState({ selectionFiltres: filtres})
      this.setState({ selectionDescription: aide})
      this.setState({ tab: copytab})
      this.setState({ selections: copySelections})
    }

    keyPress = (e) => {
      if(e.keyCode == 13){
         this.wsCriteres();
      }
   }

   keyPressFiltre = (e) => {
    if(e.keyCode == 13){
      this.wsFiltres(this.state.selectedCritere);
    }
 }

   wsFiltresByCritere = (obj) => {
    fetch(
      Const.URL_WS_FILTRES + `?idCritere=${obj.idCritere}&search=`,
      { retry: 0, retryDelay: 1000 }
    )
    .then((res) => res.json())
    .then((data) => {
        this.setState({ filtres: data})
        let aide = this.state.aideCritere;
        this.setState({ descriptionAide: aide[obj.idCritere]})
    })
    .catch((error) => {
        console.log("Request failed", error);
    });
  }

    handleCritereSelect = (index, obj) => {

      this.setState({ titreCritere: obj.label });
      this.setState({ selectedIndex: index });
      this.setState({ selectedCritere: obj });

      this.setState({ rechercheFiltre: "" }, () => {
        this.wsFiltres(obj);  
      });
      
      
    }

    wsFiltres = (obj) => {
      fetch(
        Const.URL_WS_FILTRES + `?idCritere=${obj.id}&search=${encodeURIComponent(this.state.rechercheFiltre)}`,
        { retry: 0, retryDelay: 1000 }
      )
      .then((res) => res.json())
      .then((data) => {
          this.setState({ filtres: data})
          this.tab[obj.id] = obj.aide
          this.setState({ aideCritere: this.tab})
          this.setState({descriptionAide: obj.aide})
      })
      .catch((error) => {
          console.log("Request failed", error);
      });
    }

    wsSelectionSave = () => {
      let utilisateur = localStorage.getItem("utilisateur_dtf");
      fetch(
        Const.URL_WS_SELECTION_SAVE + `?utilisateur=${utilisateur}
        &selectionNom=${this.state.selectionNom}
        &selectionDescription=${encodeURIComponent(this.state.selectionDescription)}
        &selectionFiltres=${this.state.selectionFiltres}
        &selectionDossier=${this.state.dossier}`,
        { retry: 0, retryDelay: 1000 }
      )
      .then((res) => res.json())
      .then((data) => {
        if(data == true){
          this.setState({openSnackBar: true});
        }else{
          alert("Erreur");
        }
      })
      .catch((error) => {
          console.log("Request failed", error);
      });
      this.closeDialogEnregistrer();
    }

    aideCategorie = (pdf) => {
      this.handleAide(pdf);
    }

    wsCriteres = () => {    
        fetch(
            Const.URL_WS_CRITERES + `?categorie=CRITERE_PARTANT&sousCategorie=${this.state.sousCategorie}&search=${this.state.recherche}`,
            { retry: 0, retryDelay: 1000 }
        )
        .then((res) => res.json())
        .then((data) => {
            this.setState({ criteres: data})
            
            let o = {};
            o.id = data.criteres[0].id;
            o.aide = data.criteres[0].label;
            o.isPro = data.criteres[0].isPro;

            this.setState({ titreCritere: o.aide });
            this.setState({ selectedIndex: 0 });
            
            this.setState({selectedCritere: o});
            this.wsFiltres(o);
        })
        .catch((error) => {
            console.log("Request failed", error);
        });
    }



    render() {
        return (
            <div>
               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomStyle:"inset", borderBottomColor:"grey" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.5em"}}>- Éditeur de sélection</span> 
                  </div>
                </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{float:"right", margin:"0.5em"}}>
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={() => this.openDialogEnregistrer()}
                  >
                  <CloudUploadIcon style={{color:"black"}} />
                  </IconButton>

                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectHome}
                  >
                  <CloseIcon style={{color:"black"}} />
                  </IconButton>
                </div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            <Box sx={{ width: '40vw', maxWidth: '40vw', height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white' , overflow:'scroll', overflowX:'hidden' }}>
                <List>
                <ListSubheader>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Liste des critères</span>
                  <FormControl style={{marginTop:"1em"}} fullWidth>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Catégorie</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.sousCategorie}
                    label="Catégorie"
                    defaultValue={"Filtres pré-enregistrés"}
                    onChange={this.handleSousCategorie}
                  >
                  
           

                  <MenuItem value={"INDICATEUR_PRE_ENREGISTRE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Filtres pré-enregistrés - Pour bien commencer</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Indicateurs (oui / non) parfaits pour bien débuter avec les critères !</span>}>
                  <HelpCenterOutlinedIcon style={{float:"right", color:"gray"}}/>
                  </Tooltip>
                  </MenuItem>

                  <MenuItem value={"INDICATEUR_PRE_ENREGISTRE_TROTTEUR_DERN"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Filtres pré-enregistrés - dernière course</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Indicateurs (oui / non) spécifiques sur la dernière course des trotteurs</span>}>
                  <HelpCenterOutlinedIcon style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>

                  <MenuItem value={"INDICATEUR_PRE_ENREGISTRE_PACK_1"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Filtres pré-enregistrés</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Indicateurs (oui / non) spécifiques sur les trotteurs</span>}>
                  <HelpCenterOutlinedIcon style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  
                  <Divider variant="middle"/>
                  
                  <MenuItem value={"TROTTEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Informations trotteurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Informations générales sur les trotteurs</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"DRIVER"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Informations drivers</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Informations générales sur les drivers et les jockeys</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"ENTRAINEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Informations entraineurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Informations générales sur les entraineurs</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"PROPRIETAIRE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Informations propriétaires</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Informations générales sur les propriétaires</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  
                  <Divider variant="middle"/>

                  <MenuItem value={"TROTTEUR_INDICATEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Indicateurs trotteurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection d'indicateurs de performances sur les chevaux calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"DRIVER_INDICATEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Indicateurs drivers</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection d'indicateurs de performances sur les drivers et jockeys calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"ENTRAINEUR_INDICATEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Indicateurs entraineurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection d'indicateurs de performances sur les entraineurs calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>

                  <Divider variant="middle"/>

                  <MenuItem value={"TROTTEUR_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur les trotteurs calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_CORDE_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - cordes</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - corde, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_DEFERRAGE_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - déferrages</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - déferrage, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_DISTANCE_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - distances</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - distance, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_DRIVER_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - drivers</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - driver, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_DRIVER_HIPPODROME_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - drivers - hippodromes</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - driver - hippodrome, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_ENTRAINEUR_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - entraineurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - entraineur, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/>
                  </Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_HIPPODROME_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - hippodromes</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - hippodrome, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_AUTOSTART_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - autostarts</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - autostart, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_QUINTE_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques trotteurs - quintés</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association trotteur - quinté, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>

                  <Divider variant="middle"/>

                  <MenuItem value={"DRIVER_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur les drivers et jockeys, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"DRIVER_ENTRAINEUR_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers - entraineurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association driver - entraineur, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"DRIVER_HIPPODROME_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers - hippodromes</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association driver - hippodrome, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"DRIVER_QUINTE_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques drivers - quintés</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association driver - quinté, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>

                  <Divider variant="middle"/>

                  <MenuItem value={"ENTRAINEUR_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques entraineurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur les entraineurs, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"ENTRAINEUR_HIPPODROME_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques entraineurs - hippodromes</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association entraineur - hippodrome, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"ENTRAINEUR_QUINTE_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Statistiques entraineurs - quintés</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de statistiques sur l'association entraineur - quinté, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  
                  <Divider variant="middle"/>

                  <MenuItem value={"TROTTEUR_CLS_INDICATEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement indicateurs trotteurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les indicateurs trotteurs, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"DRIVER_CLS_INDICATEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement indicateurs drivers</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les indicateurs drivers, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"ENTRAINEUR_CLS_INDICATEUR"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement indicateurs entraineurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les indicateurs entraineurs</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>

                  <Divider variant="middle"/>

                  <MenuItem value={"TROTTEUR_CLS_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les statistiques des trotteurs, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_HIPPODROME_CLS_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - hippodromes</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les statistiques association trotteur - hippodrome, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_DISTANCE_CLS_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - distances</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les statistiques association trotteur - distance, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  <MenuItem value={"TROTTEUR_DRIVER_CLS_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - drivers</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les statistiques association trotteur - driver, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  
                  {/*<MenuItem value={"TROTTEUR_CORDE_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - cordes</span></MenuItem>
                  <MenuItem value={"TROTTEUR_DEFERRAGE_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - déferrages</span></MenuItem>
                  <MenuItem value={"TROTTEUR_ENTRAINEUR_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - entraineurs</span></MenuItem>
                  <MenuItem value={"TROTTEUR_HIPPODROME_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - hippodromes</span></MenuItem>
                  <MenuItem value={"TROTTEUR_DRIVER_HIPPODROME_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques trotteurs - drivers - hippodromes</span></MenuItem>
                  */}
                  

                  <MenuItem value={"DRIVER_CLS_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques drivers</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les statistiques des drivers et jockeys, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon  style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  {/*<MenuItem value={"DRIVER_ENTRAINEUR_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques drivers - entraineurs</span></MenuItem>
                  <MenuItem value={"DRIVER_HIPPODROME_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques drivers - hippodromes</span></MenuItem>
                  */}
                  

                  <MenuItem value={"ENTRAINEUR_CLS_STATISTIQUE"} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques entraineurs</span>
                  <Tooltip title={<span style={{fontSize:"14px"}} class="lato-regular">Collection de classement sur les statistiques des entraineurs, calculés par Trotstats</span>}>
                  <HelpCenterOutlinedIcon   style={{float:"right", color:"gray"}}/></Tooltip></MenuItem>
                  {/*<MenuItem value={"ENTRAINEUR_HIPPODROME_CLS_STATISTIQUE"}><span class="lato-regular" style={{fontSize:"14px"}}>Classement statistiques entraineurs - hippodromes</span></MenuItem>
                  */}
                  </Select>
                </FormControl>

                <TextField
                  style={{marginTop:"1em", marginBottom:"1em"}}
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchOutlinedIcon/>,
                    className: "lato-regular",
                  }}
                  id="outlined-controlled"
                  onKeyDown={this.keyPress}
                  autoComplete={"off"}
                  size="small"
                  label={<span style={{color:"black"}} class="lato-regular">Recherche</span>}
                  value={this.state.recherche}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({recherche: event.target.value});
                  }}
                />

                {(this.state.abo === "Premium Essai" && (
                                                  <div>
                                                    
                                                    <Alert style={{marginBottom:"1em"}} severity={"info"}>
                                                      <span style={{fontSize:"14px"}} class="lato-regular">
                                                      Essai Premium - Critères limités.
                                                       </span>
                                                    </Alert>
                                                    
                                                  </div>
                                                ))}

                </ListSubheader>
                {this.state.criteres != null && this.state.criteres.criteres.map((o, index) => (
                  <div>
                  <ListItem style={{ backgroundColor: index === this.state.selectedIndex ? '#D4D4D4' : 'transparent' }}>                   
                        
                        {o.isPro == "N" && (
                                                 <ListItemText onClick={() => this.handleCritereSelect(index, o)} style={{cursor:"pointer"}} 
                                                 primary={
                                                   <div> 
                                                       <span class="lato-regular" style={{fontSize:"14px"}}>{o.label}</span> 
                                                       {/*<HelpCenterOutlinedIcon onClick={() => this.handleAide(o)} style={{float:"right", color:"gray"}}/>*/}
                                                   </div>
                                                 } 
                                                 />  
                                               )}
                       
                                             {o.isPro == "O" && this.state.abo != "Premium Essai" && (
                                               <ListItemText onClick={() => this.handleCritereSelect(index, o)} style={{cursor:"pointer"}} 
                                               primary={
                                                 <div> 
                                                     <span class="lato-regular" style={{fontSize:"14px"}}>{o.label}</span> 
                                                     {/*<HelpCenterOutlinedIcon onClick={() => this.handleAide(o)} style={{float:"right", color:"gray"}}/>*/}
                                                 </div>
                                               } 
                                               />  
                                               )}
                       
                       
                                               {o.isPro == "O" && this.state.abo == "Premium Essai" && (
                                                <ListItemText onClick={() => this.handleCritereSelect(index, o)} style={{cursor:"pointer"}}
                                                primary={
                                                  <div> 
                                                      <span class="lato-regular" style={{fontSize:"14px"}}>{o.label}</span> 
                                                      <LockOutlinedIcon style={{float:"right", color:"black"}}/>
                                                  </div>
                                                } 
                                                />  
                                               )}
                        
                  </ListItem>
                  <Divider variant="inset" />
                  </div>
                ))}

                {this.state.criteres != null && this.state.criteres.criteres.length == 0 && (
                  <Alert style={{margin:"1em"}} severity="warning"><span style={{fontSize:"14px"}} class="lato-regular">Aucun résultat.</span></Alert>
                )}

                </List>
            </Box>

            <Box sx={{ width: '60vw', maxWidth: '60vw', height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'red', overflow:'hidden', overflowX:'hidden' }}>

            <div>
                <Box sx={{ width: '60vw', maxWidth: '60vw', height:'calc(50vh - 10px)', maxHeight:'calc(50vh - 10px)', bgcolor: 'white', overflow:'scroll', overflowX:'hidden', borderBottomWidth:"10px", borderBottomStyle:"inset" , borderBottomColor:"grey" }}>

                <ListFiltres filtres={this.state.filtres}
                tab={this.state.tab} 
                selectedCritere={this.state.selectedCritere}
                handleZoneRechercheFiltre={this.handleZoneRechercheFiltre}
                handleCheckbox={this.handleCheckbox}
                titreCritere={this.state.titreCritere}
                rechercheFiltre={this.state.rechercheFiltre}
                abo={this.state.abo}/>
                    
                </Box>

                <Box sx={{ width: '60vw', maxWidth: '60vw', height:'calc(50vh - 10px)', maxHeight:'calc(50vh - 60px)', bgcolor: 'white', overflow:'scroll', overflowX:'hidden' }}>
                
                <List>
                <ListSubheader>
                  <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Sélection</span>
                </ListSubheader>

                {this.state.descriptions != null && this.state.descriptions.map((s, index) => (
                  <div style={{margin:"1em"}}>
                  <ListItem>                   
                    <Grid container spacing={2}>
                      <Grid item xs={1}>
                        <AutoFixHighOutlinedIcon onClick={() => this.wsFiltresByCritere(s)}/>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                          <span onClick={() => this.wsFiltresByCritere(s)} class="lato-regular" style={{cursor:"pointer", fontSize:"14px"}}>{s.label}</span> 
                        </div>
                        <div>
                          <span onClick={() => this.wsFiltresByCritere(s)} class="lato-regular-italic" style={{cursor:"pointer", fontSize:"14px"}}>{s.categorie}</span> 
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                      <span onClick={() => this.wsFiltresByCritere(s)} class="lato-regular" style={{cursor:"pointer", fontSize:"14px"}}>{s.valeur}</span>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider variant="inset" />
                  </div>
                ))}

                {this.state.descriptions == null ||this.state.descriptions == "" && (
                    <div class="loadingImage">
                    <center>
                   <img width={"25%"} height={"25%"} style={{opacity:0.1}} src="https://dataturf.fr/images/brain.jpg" alt="Brain Image"/>
                   </center>
                    </div>
                )}
                </List>
                    
                </Box>
              </div>
                
            </Box>

            <Dialog
              open={this.state.openDialogEnregistrer}
              onClose={this.closeDialogEnregistrer}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold" style={{fontSize:"18px"}}>Enregistrer</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.closeDialogEnregistrer}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
                
                <TextField
                  style={{marginTop:"0.5em"}}
                  size="small"
                  fullWidth
                  autoComplete="on"
                  id="outlined-controlled"
                  defaultValue={this.state.selectionNom}
                  label={<span class="lato-regular">Nom</span>}
                  InputProps={{
                    className: "lato-regular",
                  }}
                  value={this.state.selectionNom}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({selectionNom: event.target.value});
                  }}
                />

                <FormControl fullWidth style={{marginTop:"1.5em", marginBottom:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Dossier"
                    defaultValue={"Sélections Personnelles"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Sélections Personnelles"}><span class="lato-regular" style={{fontSize:"14px"}}>Sélections personnelles</span></MenuItem>
                  <MenuItem value={"Brouillon"}><span class="lato-regular" style={{fontSize:"14px"}}>Brouillon</span></MenuItem>
                  
                  </Select>
                </FormControl>

              </DialogContent>
              <Divider/>
              <DialogActions>

              <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.wsSelectionSave}
                  >
                  <CheckOutlinedIcon style={{color:"black"}} />
                  </IconButton>

                
               
              </DialogActions>
            </Dialog>

            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              severity="success"
              onClose={this.closeSnackBar}
              message="Enregistrer"
            >
               <Alert
                  onClose={this.closeSnackBar}
                  severity="success"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                <span class="lato-regular" style={{fontSize:"14px"}}>Sélection enregistrée</span>
              </Alert>
            </Snackbar>

            <Drawer style={{width:"800px", height:"99%"}} open={this.state.openDrawerAide}  anchor={"right"} onClose={this.closeDrawerAide}>
            <Box sx={{ width: 800, height:"99%" }} role="presentation">
            
            <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.closeDrawerAide}
                  >
                  <CloseIcon style={{color:"black"}} />
                  </IconButton>

            <embed src={"https://www.trotstats.fr/documentations/criteres/" + this.state.descriptionAide} width="100%" height="100%" type='application/pdf'/>
            
            
            </Box>
            </Drawer>

            

            </div>
            </div>

        )
    }
}