import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauHistoriqueMise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        
          <Grid container spacing={2}>
          <Grid item xs={8}>
            
          </Grid>
          <Grid item xs={4}>

              <Pagination
              style={{float:"right"}}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />

          </Grid>
          </Grid>
      );
    }


    function pronostics(num, arrivee){
     
        return (
          
              <Avatar
                style={{ margin:"0.25em", backgroundColor: arrivee == 1 ? "#3ca64a" : arrivee == "2" || arrivee == "3" ? "#0089e9" : "grey", width: "45px", height: "25px", marginLeft:"12px" }}
                variant="square"
              >
                
                  <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "white" }}>{num}</span>
              </Avatar>
          
        );
      }  

      function rapportGagnant(rapport){
     
        if(rapport > 0){
          return (
          
            <Avatar
              style={{ margin:"0.25em", backgroundColor: "#3ca64a", width: "45px", height: "25px" }}
              variant="square"
            > 
                <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "white" }}>{(rapport / 100).toFixed(2)}</span>
            </Avatar>
        
          );
        }else{
          return (
          
              
                  <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "black" , marginLeft:"10px" }}>-</span>
              
          );
        }
      } 
      
      function rapportPlace(rapport){
     
        if(rapport > 0){
          return (
          
            <Avatar
              style={{ margin:"0.25em", backgroundColor: "#0089e9", width: "45px", height: "25px"}}
              variant="square"
            > 
                <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "white" }}>{(rapport / 100).toFixed(2)}</span>
            </Avatar>
        
          );
        }else{
          return (
          
                  
                  <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "black", marginLeft:"10px" }}>-</span>
              
          );
        }
      } 
        
      const columns = [
      { 
        field: 'date',
        headerName:  <span class="lato-regular">Date</span>,
        description: "#",
        width: 100,
        renderCell: (params) => (
          <span class="lato-regular">{params.formattedValue}</span>
         
        )
      },
      { 
        field: 'hippodrome',
        headerName:  <span class="lato-regular">Hippodrome</span>,
        description: "#",
        width: 250,
        renderCell: (params) => (
          <span class="lato-regular">{params.formattedValue}</span>
         
        )
      },
      { 
        field: 'heure',
        headerName:  <span class="lato-regular">Heure</span>,
        description: "#",
        width: 60,
        renderCell: (params) => (
          <span class="lato-regular">{params.formattedValue}</span>
         
        )
      },
      { 
        field: 'course',
        headerName:  <span class="lato-regular">Course</span>,
        description: "#",
        width: 100,
        renderCell: (params) => (
          <span class="lato-regular">C{params.formattedValue}</span>
         
        )
      },
      { 
        field: 'numPartant',
        headerName:  <span class="lato-regular">Cheval</span>,
        description: "#",
        width: 100,
        renderCell: (params) => (
          pronostics(params.formattedValue, params.row.arrivee)
        )
      },
      
      { 
        field: 'rapportGagnant',
        headerName:  <span class="lato-regular">SG.</span>,
        description: "#",
        width: 100,
        renderCell: (params) => (
          rapportGagnant(params.formattedValue)
         
        )
      },
      { 
        field: 'rapportPlace',
        headerName:  <span class="lato-regular">SP.</span>,
        description: "#",
        width: 100,
        renderCell: (params) => (
          rapportPlace(params.formattedValue)
         
        )
      },
      
      
  ]


    return(
      <DataGrid
      density="compact"
      hideFooter={false}
      disableRowSelectionOnClick
      rows={this.state.data}
      columns={columns}
      components={{
        Pagination: CustomPagination
      }}
      autoHeight
      getRowClassName={(params) =>
        this.state.selectedRow === params.row.id ? 'selected-row' : ''
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 20 } },
      }}
      rowsPerPageOptions={[]} />


    )
  }
}