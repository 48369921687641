import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DialogAide from "./DialogAide";


import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauMonteCarlo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      aide:false
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  openAide = () => {
    this.setState({ aide: true})
  }

  closeAide = () => {
    this.setState({ aide: false})
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function reussite(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function rde(data){
      
      if(data >= 90 && data < 100){
        return (
          <div style={{display:"flex"}}>
            <div>
          {data} % 
          </div>
          <div style={{marginLeft:"1em", marginTop:"-0.3em"}}>
          <SentimentDissatisfiedOutlinedIcon color="warning" />
          </div>
          </div>
        )
      }else if(data < 90){
        return (
          <div style={{display:"flex"}}>
            <div>
            {data} %
          </div>
          <div style={{marginLeft:"1em", marginTop:"-0.3em"}}>
          <SentimentDissatisfiedOutlinedIcon color="error" />
          </div>
          </div>
        )
      }
      
      else if(data >= 100){
        return (
          <div style={{display:"flex"}}>
            <div>
            {data} %
          </div>
          <div style={{marginLeft:"1em", marginTop:"-0.3em"}}>
          <SentimentSatisfiedAltOutlinedIcon color="success" />
          </div>
          </div>
        )
      }
    }

    return(
      <div >
{this.state.aide && (
    <DialogAide titre={"Astuce"} open={true} 
    close={this.closeAide}
    content={<span class="lato-regular">Ce tableau est basé sur des simulations Monte-Carlo, où un seul cheval par course est joué parmi la sélection, afin d’estimer les performances à long terme sur les paris en simple placé.</span>}
    />
    )}

      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell><span class="lato-bold">Performance estimée</span></TableCell>
            <TableCell>

            <IconButton style={{float:"right"}} aria-label="settings">
              <Tooltip title={<span class="lato-regular">Aide</span>}>
                <HelpOutlineOutlinedIcon onClick={this.openAide} style={{color:"black", cursor:"pointer"}}/>
              </Tooltip>
          </IconButton>

            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
              <TableCell width={"75%"} align="left"><span class="lato-regular">Réussite moyenne :</span></TableCell>
              <TableCell><span class="lato-regular"> {reussite(this.state.data.reussiteMoyen)}</span></TableCell>
            </TableRow>
            <TableRow>
              <TableCell width={"75%"} align="left"><span class="lato-regular">Rendement moyen :</span></TableCell>
              <TableCell><span class="lato-regular"> {rde(this.state.data.rendementMoyen)}</span></TableCell>
            </TableRow>
            
        </TableBody>
      </Table>
      </div>
    )
  }
}