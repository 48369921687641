import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Switch from '@mui/material/Switch';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import Tooltip from '@mui/material/Tooltip';


import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauDataPartants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

 
 

  /************************************************************************************
   * RENDU
   ************
   
   ***********************************************************************/

  render() {

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        
          <Grid container spacing={2}>
          <Grid item xs={8}>
            
          </Grid>
          <Grid item xs={4}>

              <Pagination
              style={{float:"right"}}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />

          </Grid>
          </Grid>
      );
    }

   
    function ecart(data){
      var color = "";
        if(data == -1){
          return (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>-</span>
          </Avatar>
          </div>
          )
        }

        if(data == -2){
          return (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>NC</span>
          </Avatar>
          </div>
          )
        }

        if(data == 0){
          color = "#2D7E43";
        }else if(data > 0 && data <= 4){
          color = "#E8B610";
        }else if(data > 4){
          color = "#D57B1A";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"50px", height:"25px", marginTop:"0.3em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data}</span>
          </Avatar>
          </div>
        )
      
    }
        
      const columns = [
        { 
          field: 'num',
          headerName:  <span class="lato-regular">#</span>,
          description: "#",
          align: "center",
          headerAlign:"center",
          width: 90,
          renderCell: (params) => (
            <span class="lato-regular">{params.formattedValue}</span>
          )
        },
     
      { 
        field: 'trotteur',
        headerName:  <span class="lato-regular">Trotteur</span>,
        description: "#",
        headerAlign:"left",
        align: "left",
        width: 220,
        renderCell: (params) => (
          <Tooltip title={<span class="lato-regular" style={{fontSize:"14px"}}>{params.formattedValue}</span>}>
                   
                    <span class="lato-regular"> {params.formattedValue}</span>    
                   
                   </Tooltip>   
        )
      },   
      { 
        field: 'ecartGagnant',
        headerName:  <span class="lato-regular">Écart #1</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          ecart(params.formattedValue)
        )
      },
      
      { 
        field: 'ecartPlace',
        headerName:  <span class="lato-regular">Écart #3</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          ecart(params.formattedValue)
        )
      },

      { 
        field: 'ecartTop5',
        headerName:  <span class="lato-regular">Écart #5</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          ecart(params.formattedValue)
        )
      },

      { 
        field: 'ecartDriver',
        headerName:  <span class="lato-regular">Écart Dri.</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          ecart(params.formattedValue)
        )
      },

      { 
        field: 'ecartEntraineur',
        headerName:  <span class="lato-regular">Écart Entr.</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          ecart(params.formattedValue)
        )
      },

      { 
        field: 'engDriver',
        headerName:  <span class="lato-regular">Eng Driv.</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          <span class="lato-regular"> {params.formattedValue}</span> 
        )
      },

      { 
        field: 'engEntrCourse',
        headerName:  <span class="lato-regular">Eng Entr.</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          <span class="lato-regular"> {params.formattedValue}</span> 
        )
      },

      { 
        field: 'engEntrJour',
        headerName:  <span class="lato-regular">Eng Entr. Jr.</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          <span class="lato-regular"> {params.formattedValue}</span> 
        )
      },
      
      
      
  ]


    return(
      <DataGrid
      
      density="compact"
      hideFooter={true}
      getRowId={() => crypto.randomUUID()}
      disableRowSelectionOnClick
      rows={this.state.data}
      columns={columns}
      autoHeight
      
      getRowClassName={(params) =>
        this.state.selectedRow === params.row.id ? 'selected-row' : ''
      }
     />


    )
  }
}