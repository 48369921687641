import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";
import Checkbox from '@mui/material/Checkbox';


export default class FiltreAlgoProno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utilisateur:this.props.utilisateur,
      condition:-1,
      periode:-1,
      pronoJour: false,
      jour: 0,
      recherche:"",
      aideFiltre: false
    };
    
  }


  aideFiltre = () => {
    this.setState({ aideFiltre: true})
  }

  closeAideFiltre = () => {
    this.setState({ aideFiltre: false})
  }

  componentWillReceiveProps(props) {
    this.setState({ utilisateur: props.utilisateur})
  }

  componentDidMount() {
    
  }

  keyPress = (e) => {
    if(e.keyCode == 13){
       this.props.filtres(this.state.condition, this.state.periode, this.state.jour,
        this.state.recherche)
    }
  }

  handleCondition = (event) => {
    this.setState({ condition: event.target.value});
    this.props.filtres(event.target.value, this.state.periode, this.state.jour,
      this.state.recherche)
  }

  handlePeriode = (event) => {
    this.setState({ periode: event.target.value});
    this.props.filtres(this.state.condition, event.target.value, this.state.jour,
      this.state.recherche)
  }

  

  handleCheckbox = () => {
    if(this.state.pronoJour){
      this.setState({pronoJour: false})
      this.setState({jour: 0})
      this.props.filtres(this.state.condition, this.state.periode, 0,
        this.state.recherche)
    }else{
      this.setState({pronoJour: true})
      this.setState({jour: 1})
      this.props.filtres(this.state.condition, this.state.periode, 1,
        this.state.recherche)
    }

   
  }
 
  


  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    return(
      <div>

    {this.state.aideFiltre && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideFiltre}
    content={<span class="lato-regular" style={{fontSize:"15px"}}>
      <b>
      Comment utiliser le bloc Filtres ?
      </b>
      <br/><br/>
      <b>
      1 - Recherche :
      </b>
      <br/><br/>
      Le champ "Recherche" permet d’effectuer une recherche textuelle au sein des critères des pronostics. Par exemple, en saisissant "hippodrome", vous retrouverez tous les pronostics dont les critères de sélection contiennent ce mot.
      <br/><br/>
      <b>
      2 - Condition :
      </b>
      <br/><br/>
      Le filtre "Condition" permet de sélectionner les conditions de course prises en compte par le pronostic.
      <br/><br/>
      <b>
      3 - Période :
      </b>
      <br/><br/>
      Le filtre "Période" permet de sélectionner les pronostics basés sur la période de test choisie.
      <br/><br/>
      
      
      </span>}
    />
    )}

      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              F
          </Avatar>
          }
          action={
          <div>
         
              <HelpOutlineOutlinedIcon onClick={this.aideFiltre}  style={{color:"black", margin:"0.3em", cursor:"pointer"}}/>
         
         
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Filtres</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Recherche de pronostics.</span>}
      />
      <CardContent>
     
        <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchOutlinedIcon/>,
                    className: "lato-regular",
                  }}
                  id="outlined-controlled"
                  onKeyDown={this.keyPress}
                  autoComplete={"off"}
                  size="small"
                  label={<span style={{color:"black"}} class="lato-regular">Recherche</span>}
                  value={this.state.recherche}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({recherche: event.target.value});
                  }}
                />

<FormControl fullWidth style={{ marginTop:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Condition</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.condition}
                    label="Condition"
                    defaultValue={-1}
                    onChange={this.handleCondition}
                  >
                  <MenuItem value={-1}><span class="lato-regular" style={{fontSize:"14px"}}>-</span></MenuItem>
                  <MenuItem value={19}><span class="lato-regular" style={{fontSize:"14px"}}>Toutes les courses</span></MenuItem>
                  <MenuItem value={37}><span class="lato-regular" style={{fontSize:"14px"}}>Entre 8 et 12 partants</span></MenuItem>
                  </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginTop:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Période</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.periode}
                    label="Dossier"
                    defaultValue={-1}
                    onChange={this.handlePeriode}
                  >
                  <MenuItem value={-1}><span class="lato-regular" style={{fontSize:"14px"}}>-</span></MenuItem>
                  <MenuItem value={6}><span class="lato-regular" style={{fontSize:"14px"}}>Trois derniers mois</span></MenuItem>
                  <MenuItem value={11}><span class="lato-regular" style={{fontSize:"14px"}}>Depuis le 01/01/2023</span></MenuItem>
                  
                  </Select>
        </FormControl>


              <Grid container spacing={2}>
                      <Grid  item xs={12}>
                        <div style={{marginTop:"0.5em"}}>
                          <Checkbox
                                size="small"
                                checked={this.state.pronoJour}
                                onChange={() => this.handleCheckbox()}
                                inputProps={{ 'aria-label': 'controlled' }}
                                label="hello"
                              />
                              <span class="lato-regular" style={{cursor:"pointer", fontSize:"14px", marginLeft:"-0.2em"}} onClick={() => this.handleCheckbox()}>Seulement pronostics du jour</span>
                        </div>
                      </Grid>
                </Grid>

       
         

    
      </CardContent>
      </Card>

     
      </div>


    )
  }
}