import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Box from '@mui/material/Box';
import Pronostics from './composant/Pronostics';
import Editeur from './composant/Editeur';
import EditeurSelection from './composant/EditeurSelection';
import EditeurConditionCourse from './composant/EditeurConditionCourse';
import ChoixSelection from './composant/ChoixSelection';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditeurTableau from './composant/EditeurTableau';
import { Margin } from '@mui/icons-material';
import Synthese from './composant/Synthese';
import Bibliotheque from './composant/Bibliotheque';
import TableauBord from './composant/TableauBord';
import * as Const from "./Constant";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
{/* 
Ecran 1: liste des méthodes public et privée (dossier).
Ecran 2 : affichage des pronostics pour les courses du jour (filtrage par course)
          -- affiche les pronotics + génére une synthèse
Ecran 3 : editeur de méthode

$env:NODE_OPTIONS="--openssl-legacy-provider"

*/}


document.addEventListener('keydown', function(event) {
  if (event.key === 'F1') {
      event.preventDefault(); // Empêche le comportement par défaut de F1 (ouverture de l'aide)
      window.location.href = Const.URL + 'synthese'; // Remplace par ton URL
  }

  else if (event.key === 'F2') {
    event.preventDefault(); // Empêche le comportement par défaut de F1 (ouverture de l'aide)
    window.location.href = Const.URL + 'pronostics'; // Remplace par ton URL
  }

  else if (event.key === 'F3') {
    event.preventDefault(); // Empêche le comportement par défaut de F1 (ouverture de l'aide)
    window.location.href = Const.URL + 'editeur'; // Remplace par ton URL
  }

  else if (event.key === 'F4') {
    event.preventDefault(); // Empêche le comportement par défaut de F1 (ouverture de l'aide)
    window.location.href = Const.URL + 'editeur/selection'; // Remplace par ton URL
  }

  else if (event.key === 'F5') {
    event.preventDefault(); // Empêche le comportement par défaut de F1 (ouverture de l'aide)
    window.location.href = Const.URL + 'editeur/condition'; // Remplace par ton URL
  }

  else if (event.key === 'F6') {
    event.preventDefault(); // Empêche le comportement par défaut de F1 (ouverture de l'aide)
    window.location.href = Const.URL + 'editeur/bibliotheque'; // Remplace par ton URL
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
        <Routes>
           
            <Route
              path="/"
              element={<TableauBord />}
            ></Route>
            <Route
              path="/synthese"
              element={<Synthese />}
            ></Route>
            <Route
              path="/pronostics"
              element={<Pronostics />}
            ></Route>
            <Route
              path="/editeur"
              element={<Editeur />}
            ></Route>
            <Route
              path="/algoProno"
              element={<Bibliotheque />}
            ></Route>
            <Route
              path="/editeur/selection"
              element={<EditeurSelection />}
            ></Route>
            <Route
              path="/editeur/condition"
              element={<EditeurConditionCourse />}
            ></Route>
            <Route
              path="/editeur/tableau"
              element={<EditeurTableau />}
            ></Route>
            
          </Routes>
  </Router>
);