import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import Pagination from "@mui/material/Pagination";
import * as Const from "../Constant";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import DialogResultat from './DialogResultat';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";
import DialogFiche from "./DialogFiche";
import AlertTitle from '@mui/material/AlertTitle';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ReadMoreOutlinedIcon from '@mui/icons-material/ReadMoreOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableauDataPartants from "./TableauDataPartants";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class SyntheseResultat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      synthese:this.props.synthese,
      course:this.props.course,
      loader: false,
      tabSelected: "score",
      aideNumero: false,
      aidePerf: false,
      aideSynthese: false,
      aidePronostics:false,
      aidePartant: false,
      aideArrivee: false,

      openDialog: false,
      result: null,
      maListe: [],
      openFicheDialog: false,
      partantSelect: ""
    };
    
  }

  handleTab = (event: React.SyntheticEvent, newValue: string) => {
    this.setState({ tabSelected: newValue})
  }

  aideSynthese = () => {
    this.setState({ aideSynthese: true})
  }

  aidePronostics = () => {
    this.setState({ aidePronostics: true})
  }

  aideArrivee = () => {
    this.setState({ aideArrivee: true})
  }

  aidePartant = () => {
    this.setState({ aidePartant: true})
  }

  aideNumero = () => {
    this.setState({ aideNumero: true})
  }

  aidePerf = () => {
    this.setState({ aidePerf: true})
  }

  handleOpenFicheDialog = () => {
    this.setState({ openFicheDialog: true})
  }

  handleCloseFicheDialog = () => {
    this.setState({ openFicheDialog: false})
  }

  closeAideNumero = () => {
    this.setState({ aideNumero: false})
  }

  closeAidePronostics = () => {
    this.setState({ aidePronostics: false})
  }

  closeAideArrivee = () => {
    this.setState({ aideArrivee: false})
  }

  closeAidePartant = () => {
    this.setState({ aidePartant: false})
  }

  closeAideSynthese = () => {
    this.setState({ aideSynthese: false})
  }

  closeAidePerf = () => {
    this.setState({ aidePerf: false})
  }

  componentWillReceiveProps(props) {
    this.setState({ synthese : props.synthese})
    this.setState({ course : props.course}) 
  }

  componentDidMount() {
    
  }

  closeDialog = () => {
    this.setState({openDialog: false});
  }

  openFiche = (num) => {
    this.state.maListe = [];

    for (const [key, value] of this.state.synthese.pronostics.entries()) {
      if(num == value.pronostics[0].p1
        || num == value.pronostics[0].p2
        || num == value.pronostics[0].p3
        || num == value.pronostics[0].p4
        || num == value.pronostics[0].p5
        || num == value.pronostics[0].p6
        || num == value.pronostics[0].p7
        || num == value.pronostics[0].p8
      ){
        console.log(value.methode.description)
        this.state.maListe.push(value);
      }

    for (const [key, value] of this.state.synthese.partants.entries()) {
      
      if(Number(value.num) == Number(num)) {
        console.log("trouve")
        this.setState({ partantSelect: value }, () => {
          this.handleOpenFicheDialog();
        });
      }
    }
    
  }
}
  
  select = (id) => {
    this.setState({loader: true});
    fetch(
      Const.URL_WS_METHODE_EXEC + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&methodeID=${id}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({loader: false});
        this.setState({result: data});
        this.setState({openDialog: true});
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.setState({loader: false});
      });
  }
 

  

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function format(data){
      data = Number(data);
      return data.toLocaleString('fr-FR');
    }

    function point(data){
      var color = "";

        if(data >= 1){
          color = "#2D7E43";
          data = data.toString()
          data = "+ " + data;
        }else if(data < 0){
          color = "#C53419";
          data = data.toString().replace("-", "");
          data = "- " + data;
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"70px", height:"30px"}} variant="square">
          <span class="lato-regular" style={{ fontSize:"17px", color:"white"}}>{data}</span>
        </Avatar>
        )
      
    }

    function reussite(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"70px", height:"30px"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"17px", color:"white"}}>{data} %</span>
          </Avatar>
        )
      
    }

    function score(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"70px", height:"30px"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"16px", color:"white"}}>{data}</span>
          </Avatar>
        )
      
    }

   

    function color(num, syn1, syn2, syn3, syn4, syn5){
      if(num == syn1){
        return "#66B230"
      }

      if(num == syn2){
        return "#FF8F60"
      }

      if(num == syn3){
        return "#FFAA16"
      }

      if(num == syn4){
        return "#5B86F2"
      }

      if(num == syn5){
        return "#B491FF"
      }
      
      
      return "";
    }

    return(
      <div >


    {this.state.aideNumero && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideNumero}
    content="Liste des numéros PMU des partants de la course, sélectionnés par les pronostics."
    />
    )}

    {this.state.aideArrivee && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideArrivee}
    content="Les cinq premiers chevaux de la course."
    />
    )}

    {this.state.aidePartant && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAidePartant}
    content={<span class="lato-regular">Informations synthètiques sur les chevaux de la course.<br/><br/>
      Ce score sur 100 points est calculé en fonction des performances du trotteur au cours des trois derniers mois et reflète sa probabilité de se classer parmi les trois premiers.
      </span>}
    />
    )}

    {this.state.aidePronostics && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAidePronostics}
    content="Liste des pronostics constituant la synthèse."
    />
    )}

    {this.state.aidePerf && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAidePerf}
    content={<div><span class='lato'>
      <b>
      Comment interpréter les indicateurs Performance et Points ?
      </b>
      <br/><br/><br/>
      <b>
      1 - Performance :
      </b>
      <br/><br/>
      Cet indicateur reflète la probabilité qu’au moins un des chevaux mentionnés dans les pronostics termine parmi les trois premiers. Il est calculé sur la base des données historiques pour vous offrir une estimation fiable.
      <br/><br/>
      <b>
      2 - Points :
      </b>
      <br/><br/>
      Les points représentent les Bonus ou Malus attribués aux chevaux présents dans les pronostics. Ces ajustements permettent d’affiner l’analyse en tenant compte de divers facteurs de performance.
      </span></div>}
    />
    )}

    {this.state.aideSynthese && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideSynthese}
    content={<span class="lato-regular">
      <b>Comment lire la Synthèse ?</b>
      <br/><br/>
      La Synthèse vous aide à identifier les chevaux les plus fréquemment mentionnés dans les pronostics pour la course sélectionnée.
      <br></br><br></br>Pour simplifier la création de votre jeu, concentrez-vous sur deux ou trois chevaux parmi eux.</span>}
    />
    )}
      
      <Card>
      <CardHeader
          avatar={
          
              <span style={{color:"blue", fontSize:"27px"}}>C{this.state.course.numCourse}</span>
          
          }
          action={
          <div>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"13px"}}>{this.state.course.course}</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >{this.state.course.nbPartant} partants - {this.state.course.discipline} - {format(this.state.course.distance)} m - {format(this.state.course.allocation)} euro</span>}
      />
      <CardContent>

          {this.state.course.classement != null && this.state.course.classement != "" && (
                  <Card style={{marginBottom:"1em"}}>
                  <CardHeader
                    title={
                      <div style={{display:"flex"}}>
                      <div>
                      <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Arrivée</span>
                      </div>
                      <div>
                      <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"0.2em"}} onClick={this.aideArrivee} />
                      </div>
                    </div>
                    }
                  />
                  <CardContent>
                  <div style={{display:"flex"}}>
                  {this.state.course.classement
                    .split("-")
                    .slice(0, 5) 
                    .map((item, index) => (
                    <Avatar onClick={() => this.openFiche(item)} sx={{cursor:"pointer", marginRight:"0.5em", bgcolor: color(item, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{item}</Avatar>
                  ))}
                  </div>
                  </CardContent>
                </Card>
          )}  



<Card style={{marginBottom:"1em"}}>
      <CardHeader
         title={
              
          <div style={{display:"flex"}}>
          <div>
          <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Détails</span>
          </div>
          <div>
          <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"0.2em"}} onClick={this.aidePartant} />
          </div>
        </div>
        }
      />
      <CardContent>
<Accordion style={{marginBottom:"1em"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div>
          <div style={{display:"flex"}}>
                      <div>
                      <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Les Partants</span>
                      </div>
                      <div>
                     
                      </div>
                    </div>
          </div>
          <div>
            <span class="lato-regular-italic" style={{fontSize:"14px", marginLeft:"1em"}}></span>
          </div>
        </AccordionSummary>
        <AccordionDetails>

        <TabContext value={this.state.tabSelected}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={this.handleTab} aria-label="lab API tabs example">
            <Tab label={<span style={{textTransform:"none"}}  class="lato-bold" >Score</span>} value="score" />
            <Tab label={<span style={{textTransform:"none"}}  class="lato-bold" >Tableau complémentaire</span>} value="tableau" />
            
          </TabList>
        </Box>
        <TabPanel value="score">
        {this.state.synthese.partants != null && this.state.synthese.partants.map((partant) => (
              <Card style={{marginTop:"1em"}}>
                <CardContent>
                  <Table component={Paper}>
                    
                    <TableRow>
                      <TableCell onClick={() => this.openFiche(partant.num)} style={{width:"40%", cursor:"pointer"}}>
                        <div style={{display:"flex"}}>
                          <div>
                            <Avatar sx={{ bgcolor: color(partant.num, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "",
                             this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "",
                              this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "",
                              this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "",
                              this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "",
                              this.state.synthese.synthese.syntheseParPoints[5] != null ? this.state.synthese.synthese.syntheseParPoints[5].num : ""
                            )}}>
                              <span class="lato-regular" >{partant.num}</span>
                            </Avatar>
                          </div>
                          <div style={{marginLeft:"1em"}}>  
                            <span class="lato-bold" style={{fontSize:"16px"}}>{partant.trotteur}</span>
                            <span class="lato-regular" style={{fontSize:"14px", marginLeft:"0.5em"}}>({partant.sexe}/{partant.age})</span>
                            <span class="lato-regular" style={{fontSize:"14px", marginLeft:"0.5em"}}>{partant.deferrage}</span>
                            <div>
                              <span class="lato-regular" style={{fontSize:"14px"}}>{partant.driver}</span> / <span class="lato-regular" style={{fontSize:"14px"}}>{partant.entraineur}</span>
                            </div>
                          </div>
                        </div>
                      </TableCell>

                      
                      <TableCell style={{width:"30%"}}>
                        <div>
                        <span class="lato-regular" style={{fontSize:"14px"}}>{partant.musique}</span>
                        </div>
                        <div>
                        <span class="lato-regular-italic" style={{fontSize:"14px"}}>{partant.distance} m / {format(partant.gain)} euro</span>
                        </div>
                      </TableCell>

                      <TableCell style={{width:"30%"}}>
                        <div style={{float:"right"}}>
                        <span class="lato-regular" style={{fontSize:"14px"}}>{score(partant.scoreDTF)}</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </Table>
                </CardContent>
              </Card>
            ))}

        </TabPanel>
        
        
        
        <TabPanel value="tableau">
        <Alert severity="info">
              <AlertTitle><span class="lato-bold">En cours de développement</span></AlertTitle>
              <span class="lato-regular">Retrouvez ici des informations complémentaires sur les partants comme les écarts et divers indicateurs.<br></br>
              </span>
        </Alert>

        <div style={{marginTop:"1em"}}>
          <TableauDataPartants data={this.state.synthese.partants} />
        </div>
        </TabPanel>
        
      </TabContext>

       
       
      


          
        </AccordionDetails>
      </Accordion>
</CardContent>
</Card>


          <Card>
            <CardHeader
              title={
              
                <div style={{display:"flex"}}>
                <div>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>La Synthèse</span>
                </div>
                <div>
                <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"0.2em"}} onClick={this.aideSynthese} />
                </div>
              </div>
              }
              
            />
            <CardContent>
            {this.state.synthese.synthese.syntheseParPoints.length == 0 && (
               <Alert severity="info">
                                <AlertTitle><span class="lato-bold">Synthèse non disponible.</span></AlertTitle>
                                <span class="lato-regular">Élaborez vos pronostics et obtenez immédiatement une synthèse adaptée.<br></br>
                                </span>
                                </Alert>
            )}

       

            {this.state.synthese.synthese != null && this.state.synthese.synthese.syntheseParPoints.length > 0 && (
            <Table component={Paper}>
              <TableRow>
                <TableCell style={{width:"10%"}} align="left">
                  <div>
                    <span class="lato-bold" style={{fontSize:"14px"}}>Chevaux</span>
                  </div>
                  <div>
                    <span class="lato-bold-italic" style={{fontSize:"14px", color:'#1876D2'}}>par points</span>
                  </div>
                  
                </TableCell>
                <TableCell style={{width:"90%"}} align="left">
                <div style={{display:"flex"}}>
              
                    {this.state.synthese.synthese.syntheseParPoints[0] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge onClick={() => this.openFiche(this.state.synthese.synthese.syntheseParPoints[0].num)} badgeContent={(this.state.synthese.synthese.syntheseParPoints[0].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#66B230"}} >{this.state.synthese.synthese.syntheseParPoints[0].num}</Avatar>
                        </Badge>
                      </div>
                    )}

                    {this.state.synthese.synthese.syntheseParPoints[1] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge onClick={() => this.openFiche(this.state.synthese.synthese.syntheseParPoints[1].num)} badgeContent={Number(this.state.synthese.synthese.syntheseParPoints[1].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#FF8F60"}} >{this.state.synthese.synthese.syntheseParPoints[1].num}</Avatar>
                        </Badge>
                      </div>
                    )}

                    {this.state.synthese.synthese.syntheseParPoints[2] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge onClick={() => this.openFiche(this.state.synthese.synthese.syntheseParPoints[2].num)} badgeContent={Number(this.state.synthese.synthese.syntheseParPoints[2].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#FFAA16"}} >{this.state.synthese.synthese.syntheseParPoints[2].num}</Avatar>
                        </Badge>
                      </div>
                    )}

                    {this.state.synthese.synthese.syntheseParPoints[3] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge onClick={() => this.openFiche(this.state.synthese.synthese.syntheseParPoints[3].num)} badgeContent={Number(this.state.synthese.synthese.syntheseParPoints[3].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#5B86F2"}} >{this.state.synthese.synthese.syntheseParPoints[3].num}</Avatar>
                        </Badge>
                      </div>
                    )}
                    
                    {this.state.synthese.synthese.syntheseParPoints[4] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge onClick={() => this.openFiche(this.state.synthese.synthese.syntheseParPoints[4].num)} badgeContent={Number(this.state.synthese.synthese.syntheseParPoints[4].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#B491FF"}} >{this.state.synthese.synthese.syntheseParPoints[4].num}</Avatar>
                        </Badge>
                      </div>
                    )}
        
                    
                    </div>
                </TableCell>
                </TableRow>
            </Table>
            )}

            </CardContent>
          </Card>


            {/*  CARD PRONOSTICS */}
            {this.state.synthese.synthese != null && this.state.synthese.pronostics.length > 0 && (
            <Card style={{marginTop:"1em"}}>
          <CardHeader
              title={
                <div style={{display:"flex"}}>
                <div>
                <span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Les Pronostics</span>
                </div>
                <div>
                <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"0.2em"}} onClick={this.aidePronostics} />
                </div>
              </div>

              }
            />
            <CardContent>

           

            <Table component={Paper}>
                <TableRow>
                <TableCell style={{width:"50%"}} align="left">
            <span  class="lato-bold">
              #
            </span>
            </TableCell>
            <TableCell style={{width:"40%"}} align="left">
            <div style={{display:"flex"}}>
              <div>
              <span  class="lato-bold">
              Numéros 
               </span>
              </div>
              <div>
              <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"-0.1em"}} onClick={this.aideNumero} />
              </div>
            </div>
            
            </TableCell>
            <TableCell style={{width:"10%"}} align="left">
            <div style={{display:"flex"}}>
              <div>
                <div>
                  <span  class="lato-bold">
                  Perf. 
                  </span>
                </div>
                <div>
                  <span  class="lato-bold">
                  Points 
                  </span>
                </div>

              </div>
              <div>
              <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"-0.1em"}} onClick={this.aidePerf} />
              </div>
            </div>
            </TableCell>

            </TableRow>
            </Table>
            

            {this.state.synthese.pronostics != null && this.state.synthese.pronostics.map((prono) => (
          
              
              <Card style={{marginTop:"1em"}}>
                

                <Table component={Paper}>
                <TableRow>
                <TableCell style={{width:"50%"}} align="left">
                  <div  onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer", display:"flex"}}>
                  <div>
                  <img src={prono.methode.icone} width="25px" style={{marginRight:"0.5em"}}/>
                  </div>
                  <div>
                  <span class="lato-bold" style={{fontSize:"14px"}}>{prono.methode.label}</span>
                  </div>
                  
                  </div>
                  <div onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer"}}>
                    <span class="lato-regular-italic" style={{fontSize:"14px"}}>{prono.methode.description}</span>
                  </div>
                  
                </TableCell>

                

                <TableCell style={{width:"40%"}} align="left">
                <div style={{display:"flex", gap: "1em"}}>
                
                {prono.pronostics[0].p1 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval1}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p1)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p1, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p1}</Avatar>
                    </Tooltip>
                  )}

                {prono.pronostics[0].p2 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval2}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p2)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p2, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p2}</Avatar>
                    </Tooltip>
                  )}

                  {prono.pronostics[0].p3 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval3}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p3)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p3, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p3}</Avatar>
                    </Tooltip>
                  )}

                  {prono.pronostics[0].p4 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval4}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p4)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p4, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p4}</Avatar>
                    </Tooltip>
                  )}
                  
                  {prono.pronostics[0].p5 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval5}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p5)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p5, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p5}</Avatar>
                    </Tooltip>
                  )}

                  {prono.pronostics[0].p6 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval6}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p6)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p6, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p6}</Avatar>
                    </Tooltip>
                  )}

                  {prono.pronostics[0].p7 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval7}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p7)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p7, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p7}</Avatar>
                    </Tooltip>
                  )}

                  {prono.pronostics[0].p8 != -1  && (
                    <Tooltip title={<span class="lato-regular">{prono.pronostics[0].cheval8}</span>}>
                    <Avatar onClick={() => this.openFiche(prono.pronostics[0].p8)} sx={{ cursor:"pointer", bgcolor: color(prono.pronostics[0].p8, this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{prono.pronostics[0].p8}</Avatar>
                    </Tooltip>
                  )}

                </div>
                </TableCell>

                <TableCell style={{width:"10%"}} align="left">
                  <div>
                  {reussite(prono.reussiteCourseAvecChevalPlace)}
                  </div>
                  <div>
                  {point(prono.methode.point)}
                  </div>
                  
                </TableCell>


                </TableRow>
                </Table>
              </Card>

              ))}

         
          </CardContent>

          </Card>
            )}

          

      </CardContent>
      </Card>

      

      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
      
            <DialogResultat data={this.state.result} open={this.state.openDialog} closeDialog={this.closeDialog} />


            <Dialog
      fullWidth
      open={this.state.openFicheDialog}
      maxWidth={"lg"}
      onClose={this.handleCloseFicheDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div style={{display:"flex"}}>
            <div>
              <Avatar sx={{cursor:"pointer", marginRight:"0.5em", bgcolor: color(this.state.partantSelect.num != null ? this.state.partantSelect.num : -1 , this.state.synthese.synthese.syntheseParPoints[0] != null ? this.state.synthese.synthese.syntheseParPoints[0].num : "", this.state.synthese.synthese.syntheseParPoints[1] != null ? this.state.synthese.synthese.syntheseParPoints[1].num : "", this.state.synthese.synthese.syntheseParPoints[2] != null ? this.state.synthese.synthese.syntheseParPoints[2].num : "", this.state.synthese.synthese.syntheseParPoints[3] != null ? this.state.synthese.synthese.syntheseParPoints[3].num : "", this.state.synthese.synthese.syntheseParPoints[4] != null ? this.state.synthese.synthese.syntheseParPoints[4].num : "")}}>{this.state.partantSelect.num}</Avatar>
            </div>
            <div>
                <span class="lato-regular" style={{fontSize:"15px"}}>{this.state.partantSelect.trotteur}</span>
             
              
            </div>

        </div>
      
          
       
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={this.handleCloseFicheDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
      <CloseIcon style={{color:"black"}} />
      </IconButton>
      <Divider/>
      <DialogContent>

      <Table component={Paper}>
                <TableRow>
                <TableCell style={{width:"50%"}} align="left">
           
            </TableCell>
           
            <TableCell style={{width:"10%"}} align="left">
            <div style={{display:"flex"}}>
              <div>
                <div>
                  <span  class="lato-bold">
                  Perf. 
                  </span>
                </div>
                <div>
                  <span  class="lato-bold">
                  Points 
                  </span>
                </div>

              </div>
              <div>
              <HelpOutlineOutlinedIcon style={{cursor:"pointer", marginLeft:"0.5em", marginTop:"-0.1em"}} onClick={this.aidePerf} />
              </div>
            </div>
            </TableCell>

            </TableRow>
            </Table>


      {this.state.maListe != null && this.state.maListe.map((prono) => (
        
            <Card style={{marginTop:"1em"}}>
                

                <Table component={Paper}>
                <TableRow>
                <TableCell style={{width:"50%"}} align="left">
                  <div onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer"}}>
                  
                  
                  <img src={prono.methode.icone} width="25px" style={{marginRight:"0.5em"}}/>
                   
                      <span class="lato-regular" style={{fontSize:"15px"}}>{prono.methode.description}</span>
                   
                  </div>
                  <div onClick={() => this.select(prono.methode.id)} style={{cursor:"pointer"}}>
                    <span class="lato-regular-italic" style={{fontSize:"14px"}}>{prono.methode.label}</span>
                  </div>
                  
                </TableCell>

                <TableCell style={{width:"10%"}} align="left">
                  <div>
                  {reussite(prono.reussiteCourseAvecChevalPlace)}
                  </div>
                  <div>
                  {point(prono.methode.point)}
                  </div>
                  
                </TableCell>


                </TableRow>
                </Table>
              </Card>

      ))}
      </DialogContent>


      </Dialog>


      </div>
    )
  }
}