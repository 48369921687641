import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Switch from '@mui/material/Switch';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import Tooltip from '@mui/material/Tooltip';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Pagination from "@mui/material/Pagination";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import * as Const from "../Constant";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauPronostics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      text: "",
      move: false,
      dossier:"Bases",
      idSelect: null
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
    
  }

  handleDossier = (event) => {
    
    this.setState({ dossier:event.target.value }, () => {            
      
    });
  }

  delete(id){
    this.props.delete(id);
  }

  move(id){
    this.setState({ idSelect: id})
    this.handleOpenMove();
  }

  note(id, note){
    this.setState({ idSelect: id})
    this.setState({ text: note})
    this.handleOpenNote();
  }

  handleOpenNote = () => {
    this.setState({ note: true})
  }

  handleCloseNote = () => {
    this.setState({ note: false})
  }

  handleOpenMove = () => {
    this.setState({ move: true})
  }

  handleCloseMove = () => {
    this.setState({ move: false})
  }

  handleMove = () => {
    
    fetch(
      Const.URL_WS_METHODE_MOVE + `?id=${this.state.idSelect}&dossier=${this.state.dossier}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.props.update();
      })
      .catch((error) => {
        console.log("Request failed", error);
      });

    this.handleCloseMove();
  }

  saveNote = () => {
    this.text = encodeURIComponent(this.state.text)
    fetch(
      Const.URL_WS_METHODE_SAVE_NOTE + `?id=${this.state.idSelect}&note=${this.text}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.props.update();
      })
      .catch((error) => {
        console.log("Request failed", error);
      });

    this.handleCloseNote();
  }

  handleSwitch(id){
    this.props.actif(id);
  }

  select(id){
    this.props.select(id);
  }

  handleText = (value) => {
    this.setState({ text: value})
  }

 

  /************************************************************************************
   * RENDU
   ************
   
   ***********************************************************************/

  render() {

    const modules = {
      toolbar: [
        [{ 'color': [] }, { 'background': [] }], // Ajoute les boutons de couleur
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link'],
        ['clean']
      ]
    };

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        
          <Grid container spacing={2}>
          <Grid item xs={8}>
            
          </Grid>
          <Grid item xs={4}>

              <Pagination
              style={{float:"right"}}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />

          </Grid>
          </Grid>
      );
    }

   
    function reussite(data){
      var color = "";

        if(data > 100){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function emax(data){
      var color = "#3f51b5";

        if(data < 80){
          color = "#97BA38";
        }else if(data >= 80 && data < 100){
          color = "#F1C40F"
        }else if(data >= 100 && data < 120){
          color = "#E67E22";
        } else if(data >= 120){
          color = "#E74C3C";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function point(data){
      var color = "";

        if(data >= 1){
          color = "#2D7E43";
          data = data.toString()
          data = "+ " + data;
        }else if(data < 0){
          color = "#C53419";
          data = data.toString().replace("-", "");
          data = "- " + data;
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"30px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data}</span>
          </Avatar>
          </div>
        )
      
    }

    function rde(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data >= 100){
          color = "#2D7E43";
        }else if(data >= 90 && data < 100){
          color = "#97BA38";
        }else if(data < 90){
          color = "#E8B610";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"55px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function dynamique(data){
    
        return  (
          <div align="center">
          <Avatar style={{backgroundColor:"#3f51b5", width:"53px", height:"20px", marginTop:"0.4em", align:"right"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function selection(data){
      return(
      <div align="center">
      <Avatar style={{backgroundColor:"#3f51b5", width:"50px", height:"20px", marginTop:"0.4em", align:"right"}} variant="square">
        <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{format(data)}</span>
      </Avatar>
      </div>
      )
  }

   

    function pronostics(data){
      if(data){
        return (
          <div style={{marginTop:"0.4em"}}>
          <EventAvailableOutlinedIcon style={{color:"#2D7E43"}} size="small"/>
          </div>
        )
      }else{
        return (
          <div style={{marginTop:"0.4em"}}>
          <EventBusyOutlinedIcon size="small"/>
          </div>
        )
      }
    }

    function format(data){
      data = Number(data);
      return data.toLocaleString('fr-FR');
    }
    
        
      const columns = [
      { 
        field: 'label',
        headerName:  <span class="lato-regular">#</span>,
        description: "#",
        width: 300,
        renderCell: (params) => (


          <Tooltip title={ 
            <span
            className="lato-regular-italic"
            style={{ fontSize: "15px" }}
            dangerouslySetInnerHTML={{ __html: params.row.description }}
          />}>
         <div onClick={() => this.select(params.row.id)} style={{cursor:"pointer", display:"flex"}}>
          <div>
            <img src={params.row.icone} width="20px" style={{marginRight:"0.2em", marginTop:"0.5em"}}/>
          </div>
          <div>
          <span class="lato-regular"> {params.formattedValue}</span>    
          </div>
         </div>
         </Tooltip>   
        )
      },
     
      { 
        field: 'nbCourse',
        headerName:  <span class="lato-regular">Courses jouées</span>,
        description: "#",
        headerAlign:"left",
        align: "left",
        width: 110,
        renderCell: (params) => (
          selection(params.formattedValue)
        )
      },   
      { 
        field: 'chevauxParCourse',
        headerName:  <span class="lato-regular">Chx / crse</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          selection(params.formattedValue)
        )
      },   
      { 
        field: 'dynamique',
        headerName:  <span class="lato-regular">Dynamique</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          dynamique(params.formattedValue)
         
        )
      },
      
      { 
        field: 'reussiteG',
        headerName:  <span class="lato-regular">Gagnant</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },
      
      { 
        field: 'reussitePL',
        headerName:  <span class="lato-regular">Placé</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },

      { 
        field: 'emax',
        headerName:  <span class="lato-regular">Emax</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          emax(params.formattedValue)
        )
      },
     
      /*{ 
        field: 'rendementG',
        headerName:  <span class="lato-regular">Rde. G</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          rde(params.formattedValue)
        )
      },
      
      { 
        field: 'rendementPl',
        headerName:  <span class="lato-regular">Rde. Pl</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          rde(params.formattedValue)
        )
      },*/
      { 
        field: 'point',
        headerName:  <span class="lato-regular">Pts.</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 30,
        renderCell: (params) => (
          point(params.formattedValue)
        )
      },
      { 
        field: 'pronostics',
        headerName:  <span class="lato-regular">Pronostics</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          pronostics(params.formattedValue)
        )
      },
      
      { 
        field: '   ',
        headerName:  <span class="lato-regular"></span>,
        description: "#",
        align: "right",
        width: 10,
        renderCell: (params) => (
          <IconButton disabled={params.row.dossier == "Pronostics Trotstats" ? true : false} aria-label="delete" size="small" onClick={() => this.note(params.row.id, params.row.note)}>
          <BorderColorIcon fontSize="small" />
        </IconButton>
        )
      },
      { 
        field: 'actif',
        headerName:  <span class="lato-regular">Synthèse</span>,
        description: "#",
        align: "center",
        headerAlign:"center",
        width: 90,
        renderCell: (params) => (
          <Switch
            disabled={params.row.dossier == "Pronostics Trotstats" ? true : false}
            onClick={() => this.handleSwitch(params.row.id)}
            size="small"
            checked={params.formattedValue}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        )
      },

      { 
        field: '  ',
        headerName:  <span class="lato-regular"></span>,
        description: "#",
        align: "right",
        width: 10,
        renderCell: (params) => (
          <IconButton disabled={params.row.dossier == "Pronostics Trotstats" ? true : false} aria-label="delete" size="small" onClick={() => this.move(params.row.id)}>
          <FolderSpecialOutlinedIcon fontSize="small" />
        </IconButton>
        )
      },

      { 
        field: ' ',
        headerName:  <span class="lato-regular"></span>,
        description: "#",
        align: "right",
        width: 10,
        renderCell: (params) => (
          <IconButton disabled={params.row.dossier == "Pronostics Trotstats" ? true : false} aria-label="delete" size="small" onClick={() => this.delete(params.row.id)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
        )
      },
      
      
  ]


    return(
      <div>
      <DataGrid
      density="compact"
      hideFooter={false}
      disableRowSelectionOnClick
      rows={this.state.data}
      columns={columns}
      autoHeight
      
      getRowClassName={(params) =>
        this.state.selectedRow === params.row.id ? 'selected-row' : ''
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 15 } },
      }}
      rowsPerPageOptions={[]} />

          <Dialog
              open={this.state.move}
              onClose={this.handleCloseMove}
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold">Déplacer vers</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.handleCloseMove}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
              
              <FormControl fullWidth style={{ marginBottom:"1em", marginTop:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Choix Dossier</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Choix Dossier"
                    onChange={this.handleDossier}
                  >
                  
                  <MenuItem value={"Bases"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels</span></MenuItem>
                  <MenuItem value={"Test"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Test</span></MenuItem>
                  <MenuItem value={"Archive"}><span class="lato-regular" style={{fontSize:"14px"}}>Pronostics personnels - Archive</span></MenuItem>
                  <MenuItem value={"Corbeille"}><span class="lato-regular" style={{fontSize:"14px"}}>Corbeille</span></MenuItem>
                  
                  </Select>
             </FormControl>

              </DialogContent>
              <Divider/>
              <DialogActions>
              <Grid container spacing={2}>
                      
                <Grid item xs={6}>
                   
                

                </Grid>
                <Grid item xs={6}>
                  <IconButton
                      aria-label="close"
                      style={{marginLeft:"1em", float:"right"}}
                      onClick={this.handleMove}
                    >
                    <CheckOutlinedIcon style={{color:"black"}} />
                    </IconButton>
                </Grid>
              </Grid>
              
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.note}
              onClose={this.handleCloseNote}
              fullWidth
              maxWidth={"lg"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
               {<span class="lato-bold">Notes</span>}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.handleCloseNote}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon style={{color:"black"}} />
              </IconButton>
              <Divider/>
              <DialogContent>
                
                <ReactQuill modules={modules} style={{marginTop:"1em"}} placeholder="Créer une note..." theme="snow" value={this.state.text} onChange={this.handleText} />
              

              </DialogContent>
              <Divider/>
              <DialogActions>
              <Grid container spacing={2}>
                      
                <Grid item xs={6}>
                   
                

                </Grid>
                <Grid item xs={6}>
                  <IconButton
                      aria-label="close"
                      style={{marginLeft:"1em", float:"right"}}
                      onClick={this.saveNote}
                    >
                    <SaveAsOutlinedIcon style={{color:"black"}} />
                    </IconButton>
                </Grid>
              </Grid>
              
              </DialogActions>
            </Dialog>


      </div>
    )
  }
}