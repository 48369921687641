import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Switch from '@mui/material/Switch';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import Tooltip from '@mui/material/Tooltip';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Pagination from "@mui/material/Pagination";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as Const from "../Constant";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import DialogResultat from "./DialogResultat";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauBibliotheque extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
      openDialog: false,
      result: null,
      abo: this.props.abo
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
    this.setState({ abo: props.abo})
  }

  componentDidMount() {
    
  }

  detail = (id) => {
    fetch(
      Const.URL_WS_BIBLIOTHEQUE_DETAIL+ `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&id=${id}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({result: data});
        this.setState({openDialog: true});
      })
      .catch((error) => {
        
      });
  }

  like = (id) => {
    fetch(
            Const.URL_WS_BIBLIOTHEQUE_LIKE+ `?id=${id}`,
            { retry: 0, retryDelay: 1000 }
          )
            .then((res) => res.json())
            .then((data) => {
              this.props.update();
            })
            .catch((error) => {
              
            });
  }

  save = (id) => {
    this.props.save(id);
  }

  closeDialog = () => {
    this.setState({openDialog: false});
  }


  /************************************************************************************
   * RENDU
   ************
   
   ***********************************************************************/

  render() {

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        
          <Grid container spacing={2}>
          <Grid item xs={8}>
            
          </Grid>
          <Grid item xs={4}>

              <Pagination
              style={{float:"right"}}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />

          </Grid>
          </Grid>
      );
    }


    function like(data){

      if(data.aime == 0){
        return(
          <div  onClick={() => this.like(data.id)}  align="center" style={{display:"flex", cursor:"pointer"}}>
          <div style={{marginTop:"0.40em"}}>
            <FavoriteBorderOutlinedIcon style={{color:"red"}} /> 
          </div>
          </div>
        );
      }else{
          return(
            <div align="center" style={{display:"flex", cursor:"pointer"}}>
            <div style={{marginTop:"0.15em", marginRight:"0.5em"}}>
              <span class="lato-regular">{data.aime}</span>
            </div>
            <div style={{marginTop:"0.40em"}}>
              <FavoriteOutlinedIcon style={{color:"red"}} /> 
            </div>
            </div>
          );
      }
    }

   
    function reussite(data){
      var color = "";

        if(data > 100){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"55px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

    function rde(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data >= 100){
          color = "#2D7E43";
        }else if(data >= 90 && data < 100){
          color = "#97BA38";
        }else if(data < 90){
          color = "#E8B610";
        }

        return  (
          <div align="center">
          <Avatar style={{backgroundColor:color, width:"60px", height:"20px", marginTop:"0.4em"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
          </div>
        )
      
    }

  

    function selection(data){
      return(
      <div align="center">
      <Avatar style={{backgroundColor:"#3f51b5", width:"50px", height:"20px", marginTop:"0.4em", align:"right"}} variant="square">
        <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{format(data)}</span>
      </Avatar>
      </div>
      )
  }

    function format(data){
      data = Number(data);
      return data.toLocaleString('fr-FR');
    }
    
        
      const columns = [
      
     
       { 
              field: 'label',
              headerName:  <span class="lato-regular">#</span>,
              description: "#",
              width: 200,
              renderCell: (params) => (
               
                <div
                onClick={this.state.abo !== "N" ? () => this.detail(params.row.id) : undefined}
                style={{
                  cursor: this.state.abo !== "N" ? "pointer" : "default",
                  display: "flex",
                  opacity: this.state.abo !== "N" ? 1 : 0.5,
                  pointerEvents: this.state.abo !== "N" ? "auto" : "none",
                }}
              >
                <div>
                  <SmartToyOutlinedIcon style={{ marginRight: "0.2em", marginTop: "0.2em" }} />
                </div>
                <div>
                  <span className="lato-regular">{params.formattedValue}</span>
                </div>
              </div>
              
              )
            },

      { 
        field: 'nombreCourseRetenue',
        headerName:  <span class="lato-regular">Nbre Course</span>,
        description: "#",
        headerAlign:"center",
        align: "center",
        width: 130,
        renderCell: (params) => (
          selection(params.formattedValue)
        )
      },

      { 
        field: 'nombreChevauxRetenu',
        headerName:  <span class="lato-regular">Nbre Chevaux</span>,
        description: "#",
        headerAlign:"center",
        align: "center",
        width: 130,
        renderCell: (params) => (
          selection(params.formattedValue)
        )
      },

      { 
        field: 'reussiteCourseAvecChevalPlace',
        headerName:  <span class="lato-regular">Réussite Crse</span>,
        description: "#",
        headerAlign:"center",
        align: "center",
        width: 110,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },

      { 
        field: 'reussiteMoyen',
        headerName:  <span class="lato-regular">Réussite Chx</span>,
        description: "#",
        headerAlign:"center",
        align: "center",
        width: 110,
        renderCell: (params) => (
          reussite(params.formattedValue)
        )
      },

      { 
        field: 'rendementMoyen',
        headerName:  <span class="lato-regular">Rde.</span>,
        description: "#",
        headerAlign:"center",
        align: "center",
        width: 110,
        renderCell: (params) => (
          rde(params.formattedValue)
        )
      },

      { 
        field: 'aime',
        headerName:  <span class="lato-regular"></span>,
        description: "#",
        headerAlign:"center",
        align: "center",
        width: 80,
        renderCell: (params) => {
          const value = params.value; // Récupérer la valeur de la cellule
          const isClickable = this.state.abo !== "N"; // Vérifier si le clic est autorisé
        
          return value == 0 ? (
            <div
              onClick={isClickable ? () => this.like(params.row.id) : undefined}
              align="center"
              style={{
                display: "flex",
                cursor: isClickable ? "pointer" : "default",
                opacity: isClickable ? 1 : 0.5, // Réduction de l'opacité pour indiquer la désactivation
                pointerEvents: isClickable ? "auto" : "none", // Bloque les interactions si abo == "N"
              }}
            >
              <div style={{ marginTop: "0.40em" }}>
                <FavoriteBorderOutlinedIcon style={{ color: "red" }} />
              </div>
            </div>
          ) : (
            <div
              onClick={isClickable ? () => this.like(params.row.id) : undefined}
              align="center"
              style={{
                display: "flex",
                cursor: isClickable ? "pointer" : "default",
                opacity: isClickable ? 1 : 0.5,
                pointerEvents: isClickable ? "auto" : "none",
              }}
            >
              <div style={{ marginTop: "0.15em", marginRight: "0.5em" }}>
                <span className="lato-regular">{value}</span>
              </div>
              <div style={{ marginTop: "0.40em" }}>
                <FavoriteOutlinedIcon style={{ color: "red" }} />
              </div>
            </div>
          );
        },
      },

      {
        field: ' ',
        headerName: <span className="lato-regular"></span>,
        description: "#",
        headerAlign: "center",
        align: "center",
        width: 80,
        renderCell: (params) => (
          <div
            onClick={this.state.abo !== "N" ? () => this.save(params.row.id) : undefined}
            style={{
              display: "flex",
              cursor: this.state.abo !== "N" ? "pointer" : "default",
              opacity: this.state.abo === "N" ? 0.5 : 1, // Grise l'icône si l'accès est interdit
            }}
          >
            <div style={{ marginTop: "0.40em" }}>
              <AutoFixHighOutlinedIcon />
            </div>
          </div>
        )
      }
      
  ]


    return(
      <div>
      <DataGrid
      density="compact"
      hideFooter={false}
      disableRowSelectionOnClick
      rows={this.state.data}
      columns={columns}
     
      autoHeight  
      getRowClassName={(params) =>
        this.state.selectedRow === params.row.id ? 'selected-row' : ''
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 15 } },
      }}
      rowsPerPageOptions={[15]} />


      <DialogResultat data={this.state.result} open={this.state.openDialog} closeDialog={this.closeDialog} />


      </div>
    )
  }
}