import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DialogAide from './DialogAide';
import DialogAideProgrammeCourse from './DialogAideProgrammeCourse';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import ReadMoreOutlinedIcon from '@mui/icons-material/ReadMoreOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';

export default class FiltreSynthese extends Component {

  constructor(props) {
    super(props);
    this.state = {
      utilisateur:this.props.utilisateur,
      meetings:null,
      courseSelected: null,
      condition: "Toutes les courses",
      dossier: "Pronostics Trotstats",
      date:  dayjs()
    };
    
    this.clickKey = "";
  }
  
  componentWillReceiveProps(props) {
    this.setState({ utilisateur: props.utilisateur})
  }

  componentDidMount() {
    this.callMeetings(this.state.date.toISOString().split('T')[0]);
  }

  aideProgrammeCourse = () => {
    this.setState({ aideCourseProgrammeCourse: true})
  }

  closeAideProgramme = () => {
    this.setState({ aideCourseProgrammeCourse: false})
  }

  selectDate = (event) => {
    this.props.selectedCourse(null);
    this.callMeetings(event.toISOString().split('T')[0]);
  }

  callMeetings = (date) => {
    fetch(
      Const.URL_WS_MEETINGS + `?date=${date}`,
      { retry: 0, retryDelay: 1000 }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({ meetings: data.meetings }, () => {
        });
      })
      .catch((error) => {
        console.log("Request failed", error);
      });

      this.resetClickKey();
  }

  handleCondition = () => {

  }

  resetClickKey = () => {
    this.setState({ courseSelected: null})

    var div = document.getElementById("card_" + this.clickKey);
    
    if (div) {
      div.style.backgroundColor = "#ffffff";
    }
  }

  onMouseOver = (key) => {
    if(this.clickKey != key){
      var div = document.getElementById("card_" + key);
      if (div) {
        div.style.backgroundColor = "#f0f0f0";
      }
    }
  };

  onMouseLeave = (key) => {
    if(this.clickKey != key){
      var div = document.getElementById("card_" + key);
      if (div) {
        div.style.backgroundColor = "#ffffff";
      }
    }
  };

  handleSelectCourse = (course) => {
    this.resetClickKey();
    
    this.setState({ courseSelected: course }, () => {
        this.props.selectedCourse(this.state.courseSelected, this.state.dossier);
    });

    this.clickKey = course.keyC;
    
    var div = document.getElementById("card_" + course.keyC);
    if (div) {
      div.style.backgroundColor = "#ABC4E7";
    }
  }

   
  handleSelect = (event) => {
    this.setState({ dossier:event.target.value }, () => {            
      this.props.selectedCourse(this.state.courseSelected, this.state.dossier);
    });
  }

 
  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function depart(data){
      if(data == "O"){
        return (
          <Tooltip title={<span class="lato-regular">Départ Autostart</span>}>
          <span>A</span>
          </Tooltip>
        )
      }else{
        return (
          <Tooltip title={<span class="lato-regular">Départ Volte</span>}>
          <span>V</span>
          </Tooltip>
        )
      }
      
    }

    function format(data){
      data = Number(data);
      return data.toLocaleString('fr-FR');
    }

    function quinteR(data){
      if(data == "O"){
        return (
          <span> - <Chip label="Q+" color="primary"  size="small"/></span>
        )
      }
    }

    function quinte(data){
      if(data == "O"){
        return (
          <Chip label="Q+" color="primary"  size="small"/>
        )
      }
    }
 
    

    return(
      <div>
    {this.state.aideCourseProgrammeCourse && (
    <DialogAideProgrammeCourse titre={"Programme des courses"} open={true} 
    close={this.closeAideProgramme}
    content="test"
    />
    )}
      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              M
          </Avatar>
          }
          action={
          <div>
              <HelpOutlineOutlinedIcon onClick={this.aideProgrammeCourse} style={{color:"black", margin:"0.3em", cursor:"pointer"}}/>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Meeting</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}} >Le Programme des courses</span>}
      />
      <CardContent>
      <div style={{marginBottom:"1em"}} >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker
          minDate= {dayjs(new Date(2023, 0, 1))}
          maxDate= {dayjs()}
          label="Calendrier"
          value={this.state.date}
          onChange={(newValue) => this.selectDate(newValue)}
          slotProps={{ textField: { fullWidth: true, size:"small" } }}
        />
      </LocalizationProvider>
      </div>

    
      <FormControl fullWidth style={{ marginBottom:"1em", marginTop:"1em"}}>
                  <InputLabel id="demo-simple-select-label"><span style={{color:"black"}} class="lato-regular">Choix Synthèse</span></InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.dossier}
                    label="Choix Synthèse"
                    defaultValue={"Pronostics Trotstats"}
                    onChange={this.handleSelect}
                  >
                  <MenuItem value={"Pronostics Trotstats"}><span class="lato-regular" style={{fontSize:"14px"}}>Synthèse Trotstats</span></MenuItem>
                  <MenuItem value={"Bases"}><span class="lato-regular" style={{fontSize:"14px"}}>Synthèse personnalisée</span></MenuItem>
                  <MenuItem value={"Test"}><span class="lato-regular" style={{fontSize:"14px"}}>Synthèse personnalisée - Test</span></MenuItem>
                  <MenuItem value={"Archive"}><span class="lato-regular" style={{fontSize:"14px"}}>Synthèse personnalisée - Archive</span></MenuItem>
                  
                  </Select>
        </FormControl>


      {this.state.meetings == null || Object.entries(this.state.meetings).length == 0 && (
        <Alert severity="info"><span style={{fontSize:"14px"}} class="lato-regular">Aucune réunion trouvée.</span></Alert>
      )}
   
    {this.state.meetings && Object.entries(this.state.meetings).map(([reunion, courses]) => (
               
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Grid container spacing={2}>
                      
                      <Grid item xs={12}>
                      <div style={{display:"flex"}}>
                          <div>
                            <Avatar sx={{ bgcolor: "blue" }}>R</Avatar>
                          </div>
                          <div style={{marginTop:"0.5em", marginLeft:"0.5em"}}>
                            <span style={{fontSize:"13px"}} class="lato-bold">{courses[0].hippodrome}</span>
                          </div>
                      </div>
                      </Grid>
                      
                      
                      <Grid container style={{marginLeft:"1em", marginTop:"0em"}} spacing={2}>
                        <Grid item xs={12}>
                          <span  style={{fontSize:"13px"}}  class="lato-regular">{courses[0].heureDebut} 
                            - {courses[0].nbCourse} courses 
                            - {format(courses[0].totalAlloc)} €
                            {quinteR(courses[0].quinteR)}
                            </span>
                        </Grid>
                      </Grid>
                      
              </Grid>

            </AccordionSummary>
           

 

            <AccordionDetails>

            {courses.map((course) => (

            <Paper id={"card_" + course.keyC}
            onMouseEnter={() => this.onMouseOver(course.keyC)}
            onMouseLeave={() => this.onMouseLeave(course.keyC)}
            style={{cursor:"pointer", marginBottom:"1em"}} onClick={() => this.handleSelectCourse(course)}  elevation={3} >
              <div style={{display: "flex"}}>
                <div style={{margin:"0.5em", width:"100%"}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ fontSize: "13px" }} className="lato-bold">
                  <span style={{ fontSize: "13px", color:"blue" }} className="lato-bold"> C{course.numCourse} </span> - {course.course}
                  </span>
                  <Tooltip title={<span style={{fontSize:"14px"}} className="lato-regular">{course.condition}</span>}>
                    <InfoOutlinedIcon style={{ width: "18px", marginLeft: "5px" }} />
                  </Tooltip>
                </div>
                    <div style={{marginTop:"0.5em", marginLeft:"0.5em"}}>
                    <Grid container spacing={2}>
                      
                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular">{course.heure}</span>
                      </Grid>
                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular">{course.categorie}</span>
                      </Grid>
                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular">{course.nbPartant} chx</span>
                      </Grid>
                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular">{course.discipline}</span>
                      </Grid>

                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular">{format(course.distance)} m</span>
                      </Grid>
                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular">{format(course.allocation)} €</span>
                      </Grid>
                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular"> {depart(course.autostart)}</span>
                      </Grid>
                      <Grid item xs={1} md={3}>
                        <span style={{fontSize:"13px"}} class="lato-regular">{quinte(course.quinte)}</span>
                      </Grid>
                      
                    </Grid>
                    
                    
                    </div>
                   
                </div>
              
             
              </div>
              
            </Paper>

              
            ))}

</AccordionDetails>
</Accordion>
        ))}
     
     
      

       

      </CardContent>
      </Card>
   

     
      </div>


    )
  }
}